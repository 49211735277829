import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const RegistrationSuccessLoader = Loadable({
    loader: () => import('./registrationSuccess'),
    loading: () => <ComponentLoader/>,
});

const RegistrationSuccess = () => (
    <RegistrationSuccessLoader/>
);

export default RegistrationSuccess;