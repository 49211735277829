import React, { Component } from 'react';
import { connect } from 'react-redux';

import './loader.css';

class MaterialLoader extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loader: 'spinner',
      allLoaders: ['ring', 'spinner', 'ripple', 'hourglass']
    }
  }

  render() {
    if (!this.props.showing) {
      return null;
    }
    return (
      <div className='loader-container'>
        <div className='loader-base'>
          {
            this.state.loader == 'ring' ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : null
          }
          {
            this.state.loader == 'spinner' ? <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> : null
          }
          {
            this.state.loader == 'ripple' ? <div className="lds-ripple"><div></div><div></div></div> : null
          }
          {
            this.state.loader == 'hourglass' ? <div className="lds-hourglass"></div> : null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    showing: state.loader.materialLoaderShowing
  };
}

export default connect(mapStateToProps)(MaterialLoader);
