import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}
export function* createPost(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.CREATE_POST, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchItems(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.FETCH_POSTS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.FETCH_ITEMS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchServices(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.FETCH_POSTS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.FETCH_SERVICES,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchMyItems(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let per_page = data && data.per_page ? data.per_page : 10
    let url = `${APIS.POST.MY_POSTS}?page=${page}&per_page=${per_page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.MY_ITEMS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchMyServices(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let per_page = data && data.per_page ? data.per_page : 10
    let url = `${APIS.POST.MY_POSTS}?page=${page}&per_page=${per_page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.MY_SERVICES,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchSinglePost(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let id = data && data.id ? data.id : null
    if (!id) {
      if (action && action.callbackError) {
        action.callbackError({message: 'id required'});
      }
      return
    }
    let url = `${APIS.POST.GET_POST_DETAILS}${id}`;
    const resp = yield call(CallApi.GET, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.GET_POST_DETAILS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fetchBestPosts(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.GET_BEST_POSTS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.GET_BEST_POSTS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fetchSimilarPosts(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.GET_SIMILAR_POSTS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.GET_SIMILAR_POSTS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fetchPublicItems(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let url = `${APIS.POST.USER_PUBLIC_POST}?page=${page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.FETCH_PUBLIC_ITEMS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchPublicServices(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let url = `${APIS.POST.USER_PUBLIC_POST}?page=${page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.FETCH_PUBLIC_SERVICES,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* changeFavorite(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.CHANGE_FAVORITE, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fetchFavoritePosts(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let url = `${APIS.POST.FAVORITE_POSTS}?page=${page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.POSTS.FAVORITE_POSTS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* getTotalEarnings(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.POST.TOTAL_EARNINGS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}