import { ACTIONS, mergeDataSet } from '../../config/config';

const initialState = {
  faqs: null,
  faqsQuery: null,
  helps: null,
  helpsQuery: null,
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CMS.FETCH_FAQ:
      return {
        ...state,
        faqs: mergeDataSet(state.faqs, action.payload),
        faqsQuery: action.query,
      };
    case ACTIONS.CMS.FETCH_HELP:
      return {
        ...state,
        helps: mergeDataSet(state.helps, action.payload),
        helpsQuery: action.query,
      };
    case ACTIONS.CMS.RESET_FETCH_FAQ:
      return {
        ...state,
        faqs: null,
        faqsQuery: null,
      };
    case ACTIONS.CMS.RESET_FETCH_HELP:
      return {
        ...state,
        helps: null,
        helpsQuery: null,
      };
    case ACTIONS.CMS.RESET_ALL:
      return {
        ...state,
        faqs: null,
        faqsQuery: null,
        helps: null,
        helpsQuery: null,
      };
    default:
      return state;
  }
};

export default cmsReducer;
