import { fork, take, takeLatest, all, takeEvery, takeLeading } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../config/config';
import {
    loginUser,
    socialLoginUser,
    logout,
    getMe,
    registerUser,
    resetPassword,
    forgotPassword,
    userInterest,
    verifyAccount,
    updateProfile,
    resendVerificationEmail,
    getUserBySlug,
    getSavedCards,
    deleteCard,
    saveBackAccount,
    saveIdentityInformation,
    changePassword,
} from './user.saga';

import {
  uploadFile,
  linkFile,
  deleteFile,
  getSettings,
  saveContactUs,
} from './common.saga';

import {
  getCategoryTree,
  getConditions,
} from './category.saga'

import {
  createPost,
  fetchItems,
  fetchServices,
  fetchMyItems,
  fetchMyServices,
  fetchSinglePost,
  fetchBestPosts,
  fetchSimilarPosts,
  fetchPublicItems,
  fetchPublicServices,
  changeFavorite,
  fetchFavoritePosts,
  getTotalEarnings,
} from './post.saga'

import {
  fetchSlot,
  checkAvailability,
  bookItem,
  bookSlot,
  getItemDisabledDates,
  priceCalculation,
} from './booking.saga'

import {
  fetchMyOrders,
  fetchMyBookings,
  singleOrderDetails,
  cancelOrder,
  calculateCancelFee,
} from './order.saga'

import {
  fetchCalendarItemDetails,
  updateCalendarItemPrice,
  fetchCalendarServiceDetails,
  updateServiceSlot,
  deleteServiceSlot,
  fetchSlotOrderDetails,
  addBulkSlots,
  createUpdateSlotDay,
  deleteSlotDay,
} from './calendar.saga'

import {
  sendMessage,
  listMessages,
  setMessageStatus,
} from './message.saga'

import {
  fetchReviews,
  postReview,
} from './review.saga'

import {
  fetchNotifications,
  fetchUnreadCount,
  markNotificationRead,
  deleteNotifications,
} from './notification.saga'

import {
  fetchFaq,
  fetchHelp,
} from './cms.saga'


export function* sagas() {
    yield all([
        // user related apis
        takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
        takeLatest(SAGA_ACTIONS.USER.SOCIAL_LOGIN, socialLoginUser),
        takeLatest(SAGA_ACTIONS.USER.LOGOUT, logout),
        takeLatest(SAGA_ACTIONS.USER.ME, getMe),
        takeLatest(SAGA_ACTIONS.USER.REGISTER, registerUser),
        takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
        takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
        takeLatest(SAGA_ACTIONS.USER.USER_INTEREST, userInterest),
        takeLatest(SAGA_ACTIONS.USER.VERIFY_ACCOUNT, verifyAccount),
        takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateProfile),
        takeLatest(SAGA_ACTIONS.USER.RESEND_VERIFICATION_EMAIL, resendVerificationEmail),
        takeLatest(SAGA_ACTIONS.USER.USER_BY_SLUG, getUserBySlug),
        takeLatest(SAGA_ACTIONS.USER.SAVED_CARDS, getSavedCards),
        takeLatest(SAGA_ACTIONS.USER.DELETE_CARD, deleteCard),
        takeLatest(SAGA_ACTIONS.USER.SAVE_BANK_ACCOUNT, saveBackAccount),
        takeLatest(SAGA_ACTIONS.USER.SAVE_IDENTITY_VERIFICATION, saveIdentityInformation),
        takeLatest(SAGA_ACTIONS.USER.CHANGE_PASSWORD, changePassword),

        // category saga
        takeLatest(SAGA_ACTIONS.CATEGORIES.ALL_CATEGORIES_TREE, getCategoryTree),
        takeLatest(SAGA_ACTIONS.CATEGORIES.GET_ITEM_CONDITIONS, getConditions),

        // common saga
        takeLatest(SAGA_ACTIONS.FILE.UPLOAD, uploadFile),
        takeLatest(SAGA_ACTIONS.FILE.LINK_FILE, linkFile),
        takeLatest(SAGA_ACTIONS.FILE.DELETE_FILE, deleteFile),
        takeLatest(SAGA_ACTIONS.COMMON.SETTINGS, getSettings),
        takeLatest(SAGA_ACTIONS.COMMON.CONTACT_US, saveContactUs),

        // posts saga
        takeLatest(SAGA_ACTIONS.POST.CREATE_POST, createPost),
        takeLatest(SAGA_ACTIONS.POST.FETCH_ITEMS, fetchItems),
        takeLatest(SAGA_ACTIONS.POST.FETCH_SERVICES, fetchServices),
        takeLatest(SAGA_ACTIONS.POST.FETCH_MY_ITEMS, fetchMyItems),
        takeLatest(SAGA_ACTIONS.POST.FETCH_MY_SERVICES, fetchMyServices),
        takeLatest(SAGA_ACTIONS.POST.GET_POST_DETAILS, fetchSinglePost),
        takeLatest(SAGA_ACTIONS.POST.GET_BEST_POSTS, fetchBestPosts),
        takeLatest(SAGA_ACTIONS.POST.GET_SIMILAR_POSTS, fetchSimilarPosts),
        takeLatest(SAGA_ACTIONS.POST.FETCH_PUBLIC_ITEMS, fetchPublicItems),
        takeLatest(SAGA_ACTIONS.POST.FETCH_PUBLIC_SERVICES, fetchPublicServices),
        takeLatest(SAGA_ACTIONS.POST.CHANGE_FAVORITE, changeFavorite),
        takeLatest(SAGA_ACTIONS.POST.FAVORITE_POSTS, fetchFavoritePosts),
        takeLatest(SAGA_ACTIONS.POST.TOTAL_EARNINGS, getTotalEarnings),

        // booking saga
        takeLatest(SAGA_ACTIONS.BOOKING.FETCH_SLOTS, fetchSlot),
        takeLatest(SAGA_ACTIONS.BOOKING.CHECK_ITEM_AVAILABILITY, checkAvailability),
        takeLatest(SAGA_ACTIONS.BOOKING.BOOK_ITEM, bookItem),
        takeLatest(SAGA_ACTIONS.BOOKING.BOOK_SLOT, bookSlot),
        takeLatest(SAGA_ACTIONS.BOOKING.GET_ITEM_DISABLE_DATES, getItemDisabledDates),
        takeLatest(SAGA_ACTIONS.BOOKING.PRICE_CALCULATE, priceCalculation),

        // order saga
        takeLatest(SAGA_ACTIONS.ORDERS.MY_ORDERS, fetchMyOrders),
        takeLatest(SAGA_ACTIONS.ORDERS.MY_BOOKINGS, fetchMyBookings),
        takeLatest(SAGA_ACTIONS.ORDERS.SINGLE_ORDER_DETAILS, singleOrderDetails),
        takeLatest(SAGA_ACTIONS.ORDERS.CALCULATE_CANCEL_FEE, calculateCancelFee),
        takeLatest(SAGA_ACTIONS.ORDERS.CANCEL_ORDER, cancelOrder),

        // calendar saga
        takeLatest(SAGA_ACTIONS.CALENDAR.GET_ITEM_CALENDAR_DETAILS, fetchCalendarItemDetails),
        takeLatest(SAGA_ACTIONS.CALENDAR.UPDATE_ITEM_DAY_PRICE, updateCalendarItemPrice),
        takeLatest(SAGA_ACTIONS.CALENDAR.GET_SERVICE_CALENDAR_DETAILS, fetchCalendarServiceDetails),
        takeLatest(SAGA_ACTIONS.CALENDAR.ADD_EDIT_SERVICE_SLOT, updateServiceSlot),
        takeLatest(SAGA_ACTIONS.CALENDAR.DELETE_SERVICE_SLOT, deleteServiceSlot),
        takeLatest(SAGA_ACTIONS.CALENDAR.GET_SLOT_ORDER_DETAILS, fetchSlotOrderDetails),
        takeLatest(SAGA_ACTIONS.CALENDAR.ADD_BULK_SLOT, addBulkSlots),
        takeLatest(SAGA_ACTIONS.CALENDAR.CREATE_SLOT_DAY, createUpdateSlotDay),
        takeLatest(SAGA_ACTIONS.CALENDAR.DELETE_SLOT_DAY, deleteSlotDay),

        // message saga
        takeLatest(SAGA_ACTIONS.MESSAGE.SEND_MESSAGE, sendMessage),
        takeLatest(SAGA_ACTIONS.MESSAGE.MY_MESSAGES, listMessages),
        takeLatest(SAGA_ACTIONS.MESSAGE.SET_READ, setMessageStatus),

        // review saga
        takeLatest(SAGA_ACTIONS.REVIEW.ADD_UPDATE, postReview),
        takeLatest(SAGA_ACTIONS.REVIEW.LIST, fetchReviews),

        // notification saga
        takeLatest(SAGA_ACTIONS.NOTIFICATION.LIST, fetchNotifications),
        takeLatest(SAGA_ACTIONS.NOTIFICATION.UNREAD_COUNT, fetchUnreadCount),
        takeLatest(SAGA_ACTIONS.NOTIFICATION.MARK_READ, markNotificationRead),
        takeLatest(SAGA_ACTIONS.NOTIFICATION.DELETE, deleteNotifications),

        // cms saga
        takeLatest(SAGA_ACTIONS.CMS.FETCH_FAQ, fetchFaq),
        takeLatest(SAGA_ACTIONS.CMS.FETCH_HELP, fetchHelp),
    ]);
}