import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const HomeLoader = Loadable({
    loader: () => import('./home'),
    loading: () => <ComponentLoader/>,
});

const Home = () => (
    <HomeLoader/>
);

export default Home;