import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import userReducer from './user.reducer';
import loaderReducer from './loaderReducer';
import responsiveReducer from './responsiveReducer';
import categoryReducer from './categoryReducer';
import postsReducer from './postReducer';
import commonReducer from './commonReducer';
import bookingReducer from './bookingReducer';
import orderReducer from './orderReducer';
import calendarReducer from './calendarReducer';
import messageReducer from './messageReducer';
import reviewReducer from './reviewReducer';
import notificationReducer from './notificationReducer';
import cmsReducer from './cmsReducer';

const rootReducer = combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  user: userReducer,
  loader: loaderReducer,
  responsive: responsiveReducer,
  category: categoryReducer,
  posts: postsReducer,
  common: commonReducer,
  booking: bookingReducer,
  order: orderReducer,
  calendar: calendarReducer,
  message: messageReducer,
  review: reviewReducer,
  notification: notificationReducer,
  cms: cmsReducer,
});

export default rootReducer;