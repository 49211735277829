import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}
export function* fetchReviews(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.GET, `${APIS.REVIEW.LIST}${data.slug}`, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.REVIEW.LIST,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* postReview(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.REVIEW.ADD_UPDATE, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.ORDERS.MY_ORDERS_POST_REVIEW,
        payload: resp.data.data,
        query: data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}