import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const ContactUsLoader = Loadable({
    loader: () => import('./contactus'),
    loading: () => <ComponentLoader/>,
});

const ContactUs = () => (
    <ContactUsLoader/>
);

export default ContactUs;