import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment'
import {
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
} from 'react-bootstrap';
import { actions as toastrActions } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import classnames from 'classnames'
import Img from './img'
import HomeBannerSearch from './homeBannerSearch'
import HomeBannerText from './homeBannerText'
import { URLS, ACTIONS, SAGA_ACTIONS, DEFAULT_USER_IMAGE, checkIfUserAllowedToPost } from '../../../config/config';
import UserProfileBanner from '../../profile/components/userProfileBanner'
import qs from 'query-string'
import NotificationIndicator from '../../notifications/components/notification.indicator'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    className="dropdown-toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy, user, logout, allowedToPost }, ref) => {
    let userNameArr = user && user.name ? user.name.split(' ') : [];
    let userFName = userNameArr.length ? userNameArr[0] : '';
    let userLName = userNameArr.length > 1 ? userNameArr.slice(1).join(' ') : '';
    let memberDate = moment(user.created_at).format('DD-MMM-YYYY')
    let userImage = user && user.files && user.files.FullUrl ? user.files.FullUrl : DEFAULT_USER_IMAGE
    return (
      <div
        ref={ref}
        style={style}
        className={`${className} my-account-menu-dropdown`}
        aria-labelledby={labeledBy}
      >
        <div className="myaccount-info-panel">
          <div className="myaccount-info-avatar">
            <img src={userImage} alt="" />
          </div>
          <div className="myaccount-info-content">
            <div className="myaccount-info-name">
              <h4><Link className="my-profile-link" to={URLS.MY_PROFILE}><span style={{display: 'block'}}>{userFName}</span> <h4>{userLName}</h4></Link></h4>
              <Link to={URLS.EDIT_PROFILE} className="myaccount-edit-account"></Link>
            </div>
            <p>Member Since {memberDate}</p>
          </div>
        </div>
        <ul>
          <li><Link to={allowedToPost ? URLS.CREATE_POST : URLS.EDIT_PROFILE}><span><img src="img/create-post-icon.png" /></span> Create Post</Link></li>
          <li><Link to={allowedToPost ? URLS.MY_POSTS : URLS.EDIT_PROFILE}><span><img src="img/my-post-icon.png" /></span> My Posts</Link></li>
          <li><Link to={URLS.MY_ORDERS}><span><img src="img/recived-order-icon.png" /></span> My Orders</Link></li>
          <li><Link to={allowedToPost ? URLS.MY_BOOKINGS : URLS.EDIT_PROFILE}><span><img src="img/my-booking-icon.png" /></span> My Bookings</Link></li>
          <li><Link to={URLS.FAVORITES}><span><img src="img/favorit-icon.png" /></span> Favorites</Link></li>
          <li className="logout-link"><a href="javascript:void(0);" onClick={() => {
            logout && logout()
          }}><span><img src="img/logout-icon.png" /> </span> Log out</a></li>
        </ul>
      </div>
    );
  },
);


class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      className: null,
      homeSearch: false,
      bannerText: null,
    };

    this.onScroll = this.onScroll.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.getBannerText = this.getBannerText.bind(this);
    this.closeMenuIfNeeded = this.closeMenuIfNeeded.bind(this);
    this.logout = this.logout.bind(this);
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.unlisten = this.props.history.listen(this.onChangeLocation)
    this.onChangeLocation(this.props.history.location);
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.location.search && !prevProps.isAuthenticated && this.props.isAuthenticated) {
      let parsed = qs.parse(window.location.search);
      if (parsed.redirect) {
        this.props.history.push(decodeURIComponent(parsed.redirect));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    this.unlisten && this.unlisten();
  }
  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: this.props.isDesktop ? 'top-left' : 'top-center',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }

  getBannerText() {
    switch (this.state.bannerText) {
      case URLS.HOME:
        return (
          <>
            <HomeBannerText
              title="Whatever you need, Whenever you need."
              subTitle="Rentmie has the the widest range of rental items and services"
              linkBtn
              linkBtnText="Rent"
              linkTo={this.props.isAuthenticated ? this.props.allowedToPost ? URLS.CREATE_POST : URLS.EDIT_PROFILE : URLS.LOGIN }
            />
          </>
        );
      case URLS.REGISTER:
        return (
          <>
            <HomeBannerText
              title="Registration"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.REGISTER_SUCCESS:
        return (
          <>
            <HomeBannerText
              title="Registration"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.ABOUT_US:
        return (
          <>
            <HomeBannerText
              title="About Us"
              subTitle="What makes Rentmie different?"
            />
          </>
        );
      case URLS.LOGIN:
        return (
          <>
            <HomeBannerText
              title="Login"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.FORGOT_PASSWORD:
        return (
          <>
            <HomeBannerText
              title="Forget Password"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.RESET_PASSWORD:
        return (
          <>
            <HomeBannerText
              title="Reset Password"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.VERIFY_ACCOUNT:
        return (
          <>
            <HomeBannerText
              title="Account Verification"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.FAQ:
        return (
          <>
            <HomeBannerText
              title="FAQ"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.MY_POSTS:
        return (
          <>
            <HomeBannerText
              title="Rental History"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.MY_BOOKINGS:
        return (
          <>
            <HomeBannerText
              title="Bookings Received"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.BOOKING_HISTORY:
        return (
          <>
            <HomeBannerText
              title="Bookings History"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.MY_ORDERS:
        return (
          <>
            <HomeBannerText
              title="My Orders"
              subTitle="Rentmie has the the widest range of rental items and services"
            />
          </>
        );
      case URLS.ITEM_DETAILS:
      case URLS.SERVICE_DETAILS:
        return null
      case URLS.MY_PROFILE:
        return <UserProfileBanner ownProfile/>
      case URLS.PROFILE:
        return <UserProfileBanner/>
      default:
        let route = this.state.bannerText
        let keys = Object.keys(URLS);
        let urls = keys.map(x => URLS[x]);
        if (route && urls.indexOf(route) >= 0) {
          let text = route.replace(/-/g, ' ').replace('/', '')
          return (
            <>
              <HomeBannerText
                title={text}
                subTitle="Rentmie has the the widest range of rental items and services"
              />
            </>
          );
        } else {
          return (
            <>
              <HomeBannerText
                title="404"
                subTitle="Rentmie has the the widest range of rental items and services"
              />
            </>
          )
        }
    }
  }

  closeMenuIfNeeded() {
    window.$("html, body").animate({ scrollTop: 0 }, "fast");
    setTimeout(() => {
      if (window.$('.myaccount-nav-link > .dropdown').hasClass('show')) {
        window.$('.myaccount-nav-link').trigger('click');
      }
      if (this.props.isTab || this.props.isMobile) {
        if (window.$('.navbar-collapse.collapse').hasClass('show')) {
          window.$('.navbar-toggler').trigger('click');
        }
      }
      let itemNavElement = document.getElementById('item_dropdown')
      let serviceNavElement = document.getElementById('service_dropdown')

      if (itemNavElement && itemNavElement.getAttribute('aria-expanded') == "true") {
        itemNavElement.click();
      }
      if (serviceNavElement && serviceNavElement.getAttribute('aria-expanded') == "true") {
        serviceNavElement.click();
      }
    }, 0);
  }

  logout() {
    this.closeMenuIfNeeded();
    this.props.showLoader();
    this.props.doLogout(null, () => {
      this.props.hideLoader();
      this.showToaster('success', 'Logout', 'You have been logout successfully')
    }, () => {
      this.showToaster('error', 'Unable to Logout', 'Please try again')
      this.props.hideLoader();
    })
  }

  onChangeLocation(newLocation) {
    const { isAuthenticated, user } = this.props
    if (isAuthenticated && user) {
      this.props.doFetchUnreadCount();
    }
    this.closeMenuIfNeeded();
    switch (newLocation.pathname) {
      case '':
      case URLS.HOME:
        this.setState({ className: '', homeSearch: true, bannerText: URLS.HOME });
        break;
      case URLS.LOGIN:
      case URLS.FORGOT_PASSWORD:
      case URLS.RESET_PASSWORD:
      case URLS.VERIFY_ACCOUNT:
        this.setState({ className: 'login-bg inner-banner', homeSearch: false, bannerText: newLocation.pathname });
        break;
      case URLS.PROFILE:
        this.setState({ className: 'profile-bg', homeSearch: false, bannerText: URLS.PROFILE });
        break;
      case URLS.MY_PROFILE:
        this.setState({ className: 'profile-bg', homeSearch: false, bannerText: URLS.MY_PROFILE });
        break;
      case URLS.ITEMS:
        this.setState({ className: 'inner-banner booking-item-banner', homeSearch: false, bannerText: URLS.ITEMS });
        break;
      case URLS.SERVICES:
      case URLS.ABOUT_US:
        this.setState({ className: 'inner-banner booking-item-banner', homeSearch: false, bannerText: newLocation.pathname });
        break;
      case URLS.MY_POSTS:
        this.setState({ className: 'inner-banner booking-item-banner', homeSearch: false, bannerText: URLS.MY_POSTS });
        break;
      case URLS.NOTIFICATIONS:
        this.setState({ className: 'booking-item-banner inner-banner', homeSearch: false, bannerText: URLS.NOTIFICATIONS });
        break;
      case URLS.MY_ORDERS:
        this.setState({ className: 'booking-item-banner inner-banner', homeSearch: false, bannerText: URLS.MY_ORDERS });
        break;
      case URLS.MY_BOOKINGS:
        this.setState({ className: 'booking-item-banner inner-banner', homeSearch: false, bannerText: URLS.MY_BOOKINGS });
        break;
      case URLS.BOOKING_HISTORY:
        this.setState({ className: 'booking-item-banner inner-banner', homeSearch: false, bannerText: URLS.BOOKING_HISTORY });
        break;
      case URLS.SERVICE_DETAILS:
      case URLS.ITEM_DETAILS:
        this.setState({ className: 'service_details inner-banner', homeSearch: false, bannerText: newLocation.pathname });
        break;
      case URLS.BOOKING_CALENDAR:
        this.setState({ className: 'booking-bg inner-banner', homeSearch: false, bannerText: newLocation.pathname });
        break;
      case URLS.MESSAGE:
        this.setState({ className: 'booking-item-banner inner-banner', homeSearch: false, bannerText: URLS.MESSAGE });
        break;
      case URLS.REGISTER:
      case URLS.REGISTER_SUCCESS:
        this.setState({ className: 'registration-bg inner-banner', homeSearch: false, bannerText: newLocation.pathname });
        break;
      case URLS.PAYMENT_SUCCESS:
      case URLS.USER_INTEREST:
      case URLS.EDIT_PROFILE:
      case URLS.CHECKOUT:
      case URLS.CONTACT_US:
      case URLS.CREATE_POST:
      case URLS.EDIT_POST:
      case URLS.FAQ:
      case URLS.FAVORITES:
      case URLS.HELP:
      case URLS.PRIVACY:
      case URLS.TERMS_CONDITION:
        this.setState({ className: 'inner-banner booking-item-banner', homeSearch: false, bannerText: newLocation.pathname });
        break;
      default:
        this.setState({ className: 'booking-item-banner inner-banner', homeSearch: false, bannerText: null });
      // do nothing
    }
  }

  onScroll(e) {
    let doc = document.documentElement;
    let topX = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (topX > 150) {
      document.getElementsByClassName('nav-section')[0].classList.add('header-fixed');
      document.getElementsByClassName('banner')[0].classList.add('fixed-active');
    } else {
      document.getElementsByClassName('nav-section')[0].classList.remove('header-fixed');
      document.getElementsByClassName('banner')[0].classList.remove('fixed-active');
    }
  }
  render() {
    const bannerClasses = classnames({
      'banner': true,
      [this.state.className]: !!this.state.className
    })
    let userImage = this.props.user && this.props.user.files && this.props.user.files.FullUrl ? this.props.user.files.FullUrl : DEFAULT_USER_IMAGE
    const {
      itemCategories,
      serviceCategories,
    } = this.props
    return (
      <>
        <div className={bannerClasses}>
          <div className="nav-section">
            <div className="container">
              <Navbar collapseOnSelect={true} bg="dark" variant="dark" expand="lg">
                <Link to={URLS.HOME} className="navbar-brand">
                  <Img src="img/logo.png" alt="Home" />
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <NavDropdown title="Items" id="item_dropdown">
                      {
                        itemCategories.map((c, i) => {
                          return <Link to={`${URLS.ITEMS}?category=${c.slug}`} key={i} className="dropdown-item">{c.name}</Link>
                        })
                      }
                    </NavDropdown>
                    <NavDropdown title="Services" id="service_dropdown">
                      {
                        serviceCategories.map((c, i) => {
                          return <Link to={`${URLS.SERVICES}?category=${c.slug}`} key={i} className="dropdown-item">{c.name}</Link>
                        })
                      }
                    </NavDropdown>
                    <Link to={URLS.ABOUT_US} className="nav-link">
                      About
                    </Link>
                    <Link to={URLS.FAQ} className="nav-link">
                      FAQ
                    </Link>
                    <Link to={URLS.CONTACT_US} className="nav-link">
                      Contact Us
                    </Link>
                    <Link to={URLS.HELP} className="nav-link">
                      Help
                    </Link>
                  </Nav>
                  <div className="my-2 my-lg-0">
                    <ul className="right-nav-menu">
                      {/* <li className="search-nav-link">
                        <Nav.Link href="search">
                          <Img src="img/search-icon.png" alt="Search" />&nbsp;
                        <span className="show-sm">
                            Search
                          </span>
                        </Nav.Link>
                      </li> */}
                      {
                        this.props.isAuthenticated ? <>
                          <li className="myaccount-nav-link">
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                <div className="account-avatar" style={{
                                  background:`url(${userImage}) no-repeat top center`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat'
                                }}></div> My Account
                              </Dropdown.Toggle>

                              <Dropdown.Menu as={CustomMenu} logout={this.logout} allowedToPost={this.props.allowedToPost} user={this.props.user}>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <NotificationIndicator/>
                        </> : <>
                            <li className="signup-nav-link">
                              <Link className="nav-link" to={URLS.REGISTER}>
                                <Img src="img/signup-icon.png" alt="" />&nbsp;
                          Sign Up
                        </Link>
                            </li>
                            <li className="login-nav-link">
                              <Link className="nav-link" to={URLS.LOGIN}>
                                <Img src="img/login-icon.png" alt="" />&nbsp;
                          Login
                        </Link>
                            </li>
                          </>
                      }

                    </ul>
                  </div>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
          {
            this.getBannerText()
          }
          {
            this.state.homeSearch ? <HomeBannerSearch /> : null
          }
        </div>
      </>
    )
  }
}

const sortCategories = (cats) => {
  if (!cats || !cats.length) {
    return []
  }
  return cats.sort((a, b) => a.name < b.name);
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    isAuthenticated: state.user.isAuthenticated,
    ...state.responsive,
    itemCategories: sortCategories(state.category.itemCategories),
    serviceCategories: sortCategories(state.category.serviceCategories),
    allowedToPost: checkIfUserAllowedToPost(state.user.user)
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showLoader: () => dispatch({
    type: ACTIONS.SHOW_LOADER,
  }),
  hideLoader: () => dispatch({
    type: ACTIONS.HIDE_LOADER,
  }),
  doLogout: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.USER.LOGOUT,
    payload,
    callbackSuccess,
    callbackError,
  }),
  doFetchUnreadCount: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.NOTIFICATION.UNREAD_COUNT,
    payload,
    callbackSuccess,
    callbackError,
  }),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));