import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const FavoritesLoader = Loadable({
    loader: () => import('./favorites'),
    loading: () => <ComponentLoader/>,
});

const Favorites = () => (
    <FavoritesLoader/>
);

export default Favorites;