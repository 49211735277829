import { ACTIONS, mergeDataSet } from '../../config/config';

const initialState = {
  postReviews: null,
  postReviewsQuery: null,
}

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REVIEW.LIST:
      return {
        ...state,
        postReviews: mergeDataSet(state.postReviews, action.payload),
        postReviewsQuery: action.query,
      };
    case ACTIONS.REVIEW.LIST_RESET:
      return {
        ...state,
        postReviews: null,
        postReviewsQuery: null,
      };
    default:
      return state
  }
}
export default reviewReducer;
