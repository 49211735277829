import { ACTIONS } from '../../config/config';
import moment from 'moment'

const initialState = {
  itemCalendarDetails: null,
  itemCalendarDetailsQuery: null,
  serviceCalendarDetails: null,
  serviceCalendarDetailsQuery: null,
};

const transformItemCalendarData = (payload, query) => {
  if (!payload || !query) {
    return null
  }
  let arr = []

  let startDate = moment(query.start_date, 'YYYY-MM-DD');
  let endDate = moment(query.end_date, 'YYYY-MM-DD');
  let cursor = startDate.clone();

  while(cursor.isSameOrBefore(endDate)) {
    let formattedDate = cursor.format('YYYY-MM-DD');
    // TODO: add additional condition for checking booked here
    let bookedFind = payload.bookDate.find(x => x.book_date == formattedDate)
    let specialFind = payload.specialPriceList.find(x => x.price_date == formattedDate)

    arr.push({
      date: formattedDate,
      isBooked: !!bookedFind,
      bookDetails: bookedFind,
      isSpecialPrice: !!specialFind,
      specialPrice: specialFind && specialFind.price ? Number(specialFind.price) : null
    })

    cursor.add(1, 'day');
  }
  return arr;
}

const transformOrderForService = (orderArray) => {
  if (!orderArray || !orderArray.length) {
    return null
  }
  let resp = [];

  orderArray.map((order) => {
    let findIndex = resp.findIndex(x => x.order.id === order.id)

    if (findIndex >= 0) {
      if (resp[findIndex].slots && resp[findIndex].slots.length > 0) {
        resp[findIndex].slots.push(order.slot_details)
      } else {
        resp[findIndex].slots = [order.slot_details]
      }
    } else {
      resp.push({
        order: {
          ...order,
          slot_details: undefined,
          user: undefined,
        },
        slots: [order.slot_details],
        user: order.user,
      })
    }
  })

  return resp
}

const transformServiceCalendarData = (payload, query) => {
  if (!payload || !query) {
    return null
  }
  let arr = []

  let startDate = moment(query.start_date, 'YYYY-MM-DD');
  let endDate = moment(query.end_date, 'YYYY-MM-DD');
  let cursor = startDate.clone();

  let keys = Object.keys(payload)

  while(cursor.isSameOrBefore(endDate)) {
    let formattedDate = cursor.format('YYYY-MM-DD');
    let findObj = keys && keys.length && keys.indexOf(formattedDate) >= 0 ? payload[formattedDate] : []

    arr.push({
      date: formattedDate,
      orders: findObj.orders && findObj.orders.order_details && findObj.orders.order_details.length ? transformOrderForService(findObj.orders.order_details) : null,
      slots: findObj.slots ? findObj.slots.map(x => {
        let startTimeObj = moment(`${x.service_start_date} ${x.service_start_time}`, 'YYYY-MM-DD HH:mm:ss')
        let endTimeObj = moment(`${x.service_start_date} ${x.service_end_time}`, 'YYYY-MM-DD HH:mm:ss')
        return {
          ...x,
          timeString: `${startTimeObj.format('H:mm')} - ${endTimeObj.format('H:mm')}`,
          timeStringMobile: `${startTimeObj.format('h:mm A')} - ${endTimeObj.format('h:mm A')}`,
          timeStartString: startTimeObj.format('h:mm A'),
          timeEndString: endTimeObj.format('h:mm A'),
        }
      }) : []
    })

    cursor.add(1, 'day');
  }
  return arr;
}

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CALENDAR.GET_ITEM_CALENDAR_DETAILS:
      return {
        ...state,
        itemCalendarDetails: transformItemCalendarData(action.payload, action.query),
        itemCalendarDetailsQuery: action.query,
      };
    case ACTIONS.CALENDAR.GET_SERVICE_CALENDAR_DETAILS:
      return {
        ...state,
        serviceCalendarDetails: transformServiceCalendarData(action.payload, action.query),
        serviceCalendarDetailsQuery: action.query,
      };
    case ACTIONS.CALENDAR.ADD_EDIT_SERVICE_SLOT:
      // TODO: add login when updating slot
      return {
        ...state,
      }
    case ACTIONS.CALENDAR.DELETE_SERVICE_SLOT:
      // TODO: add login when deleting slot
      return {
        ...state,
      }
    default:
      return state;
  }
};

export default calendarReducer;
