import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}
export function* fetchHelp(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let per_page = data && data.per_page ? data.per_page : 10
    let url = `${APIS.CMS.CMS}?page=${page}&per_page=${per_page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.CMS.FETCH_HELP,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchFaq(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    let page = data && data.page ? data.page : 1
    let per_page = data && data.per_page ? data.per_page : 10
    let url = `${APIS.CMS.CMS}?page=${page}&per_page=${per_page}`;
    const resp = yield call(CallApi.POST, url, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.CMS.FETCH_FAQ,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}