import { isNumber } from "lodash";
import moment from "moment";
/**
 * local storage key name
 */
export const STORAGE = "rm-ui-auth-key";
/**
 * base url
 */
let b_url = "https://dev-backend.rentmie.com/api/";

if (process.env.REACT_APP_STAGE === "stage") {
  b_url = "https://dev-backend.rentmie.com/api/";
} else if (process.env.REACT_APP_STAGE === "prod") {
  b_url = "https://backend.rentmie.com/api/";
} else if (process.env.REACT_APP_STAGE === "testprod") {
  b_url = "https://dev-backend.rentmie.com/api/";
}

export const BASE_URL = b_url;

/**
 * api urls
 */
export const APIS = {
  COMMON: {
    SETTINGS: `${BASE_URL}setting`,
    CONTACT_US: `${BASE_URL}contact`,
  },
  CMS: {
    CMS: `${BASE_URL}cms`,
  },
  USER: {
    LOGIN: `${BASE_URL}auth/login`,
    SOCIAL_LOGIN: `${BASE_URL}auth/social-login`,
    ME: `${BASE_URL}auth/user`,
    LOGOUT: `${BASE_URL}auth/logout`,
    REGISTER: `${BASE_URL}auth/register`,
    FORGOT_PASSWORD: `${BASE_URL}auth/forgot-password`,
    CHANGE_PASSWORD: `${BASE_URL}user/change-password`,
    RESET_PASSWORD: `${BASE_URL}auth/reset-password`,
    USER_INTEREST: `${BASE_URL}user/interest`,
    VERIFY_ACCOUNT: `${BASE_URL}verify-account`,
    UPDATE_PROFILE: `${BASE_URL}user/update-profile`,
    RESEND_VERIFICATION_EMAIL: `${BASE_URL}auth/resend-verification-email`,
    USER_BY_SLUG: `${BASE_URL}user/public-profile`,
    SAVED_CARDS: `${BASE_URL}user/cards`,
    DELETE_CARD: `${BASE_URL}user/card/delete`,
    SAVE_BANK_ACCOUNT: `${BASE_URL}user/add-bank-account`,
    SAVE_IDENTITY_VERIFICATION: `${BASE_URL}user/trulioo-verification`,
  },
  CATEGORIES: {
    ALL_CATEGORIES_TREE: `${BASE_URL}categories`,
    GET_ITEM_CONDITIONS: `${BASE_URL}get-conditions`,
  },
  FILE: {
    UPLOAD: `${BASE_URL}upload-file`,
    LINK_FILE: `${BASE_URL}link-file-model`,
    DELETE_FILE: `${BASE_URL}delete-file`,
  },
  POST: {
    CREATE_POST: `${BASE_URL}create-post`,
    FETCH_POSTS: `${BASE_URL}posts`,
    MY_POSTS: `${BASE_URL}my-posts`,
    GET_POST_DETAILS: `${BASE_URL}post/`,
    GET_BEST_POSTS: `${BASE_URL}top-posts`,
    GET_SIMILAR_POSTS: `${BASE_URL}similar-posts`,
    USER_PUBLIC_POST: `${BASE_URL}user/posts`,
    CHANGE_FAVORITE: `${BASE_URL}favourite/toggle`,
    FAVORITE_POSTS: `${BASE_URL}favourite/list`,
    TOTAL_EARNINGS: `${BASE_URL}my-posts/total-earning`,
  },
  BOOKING: {
    FETCH_SLOTS: `${BASE_URL}post/slot`,
    BOOK_SLOT: `${BASE_URL}slot/book`,
    BOOK_ITEM: `${BASE_URL}item/book`,
    CHECK_ITEM_AVAILABILITY: `${BASE_URL}item/check-availability`,
    GET_ITEM_DISABLE_DATES: `${BASE_URL}item/get-bookeddate`,
    PRICE_CALCULATE: `${BASE_URL}calculate-price`,
  },
  ORDERS: {
    MY_ORDERS: `${BASE_URL}my-orders`,
    MY_BOOKINGS: `${BASE_URL}my-bookings`,
    SINGLE_ORDER_DETAILS: `${BASE_URL}order-detail`,
    CANCEL_ORDER: `${BASE_URL}cancel-order`,
    CALCULATE_CANCEL_FEE: `${BASE_URL}order/calculate-cancellation-fee`,
  },
  CALENDAR: {
    GET_ITEM_CALENDAR_DETAILS: `${BASE_URL}calender/items`,
    UPDATE_ITEM_DAY_PRICE: `${BASE_URL}calender/update-special-price`,
    GET_SERVICE_CALENDAR_DETAILS: `${BASE_URL}calender/services`,
    ADD_EDIT_SERVICE_SLOT: `${BASE_URL}calender/update-slot-price`,
    DELETE_SERVICE_SLOT: `${BASE_URL}calender/delete-slot`,
    GET_SLOT_ORDER_DETAILS: `${BASE_URL}calender/get-order-from-slot`,
    ADD_BULK_SLOT: `${BASE_URL}calender/create-slots`,
    CREATE_SLOT_DAY: `${BASE_URL}calender/create-slot-day`,
    DELETE_SLOT_DAY: `${BASE_URL}calender/delete-slot-day`,
  },
  MESSAGE: {
    SEND_MESSAGE: `${BASE_URL}message/send`,
    MY_MESSAGES: `${BASE_URL}message/get`,
    SET_READ: `${BASE_URL}message/set-status`,
  },
  REVIEW: {
    ADD_UPDATE: `${BASE_URL}review/post`,
    LIST: `${BASE_URL}review/get/`,
  },
  NOTIFICATION: {
    LIST: `${BASE_URL}notification/get`,
    UNREAD_COUNT: `${BASE_URL}notification/unread-count`,
    MARK_READ: `${BASE_URL}notification/mark-read`,
    DELETE: `${BASE_URL}notification/delete`,
  },
};

/**
 * actions for reducers
 */
export const ACTIONS = {
  USER: {
    LOGIN: "ACTION/USER/LOGIN",
    ME: "ACTION/USER/ME",
    LOGOUT: "ACTION/USER/LOGOUT",
    REGISTER: "ACTION/USER/REGISTER",
    FORGOT_PASSWORD: "ACTION/USER/FORGOT_PASSWORD",
    RESET_PASSWORD: "ACTION/USER/RESET_PASSWORD",
    USER_BY_SLUG: "ACTION/USER/USER_BY_SLUG",
    SAVED_CARDS: "ACTION/USER/SAVED_CARDS",
  },
  SHOW_LOADER: "SHOW_MATERIAL_LOADER",
  HIDE_LOADER: "HIDE_MATERIAL_LOADER",
  ROUTE_CHANGED: "ACTIONS/ROUTE-CHANGED",
  ADD_ALERT: "ACTIONS/SWEETALERT/ADD_ALERT",
  REMOVE_ALERT: "ACTIONS/SWEETALERT/REMOVE_ALERT",
  COMMON: {
    SET_LOADING: "ACTION/COMMON/SET_LOADING",
    SET_REG_SUCCESS: "ACTION/COMMON/SET_REG_SUCCESS",
    SET_PAYMENT_SUCCESS: "ACTION/COMMON/SET_PAYMENT_SUCCESS",
    SETTINGS: "ACTION/COMMON/SETTINGS",
  },
  CMS: {
    FETCH_HELP: "ACTION/CMS/FETCH_HELP",
    FETCH_FAQ: "ACTION/CMS/FETCH_FAQ",
    RESET_FETCH_HELP: "ACTION/CMS/RESET_FETCH_HELP",
    RESET_FETCH_FAQ: "ACTION/CMS/RESET_FETCH_FAQ",
    RESET_ALL: "ACTION/CMS/RESET_ALL",
  },
  RESPONSIVE: {
    SET_DESKTOP: "ACTION/RESPONSIVE/SET_DESKTOP",
    SET_TAB: "ACTION/RESPONSIVE/SET_TAB",
    SET_MOBILE: "ACTION/RESPONSIVE/SET_MOBILE",
  },
  CATEGORIES: {
    ALL_CATEGORIES_TREE: "ACTION/CATEGORIES/ALL_CATEGORIES_TREE",
    GET_ITEM_CONDITIONS: "ACTION/CATEGORIES/GET_ITEM_CONDITIONS",
  },
  POSTS: {
    FETCH_ITEMS: "ACTION/POSTS/FETCH_ITEMS",
    FETCH_SERVICES: "ACTION/POSTS/FETCH_SERVICES",
    RESET_FETCH_ITEMS: "ACTION/POSTS/RESET_FETCH_ITEMS",
    RESET_FETCH_SERVICES: "ACTION/POSTS/RESET_FETCH_SERVICES",
    MY_ITEMS: "ACTION/POSTS/MY_ITEMS",
    CHANGE_MY_ITEM_STATUS: "ACTION/POSTS/CHANGE_MY_ITEM_STATUS",
    MY_SERVICES: "ACTION/POSTS/MY_SERVICES",
    RESET_MY_ITEMS: "ACTION/POSTS/RESET_MY_ITEMS",
    RESET_MY_SERVICES: "ACTION/POSTS/RESET_MY_SERVICES",
    GET_POST_DETAILS: "ACTION/POSTS/GET_POST_DETAILS",
    RESET_GET_POST_DETAILS: "ACTION/POSTS/RESET_GET_POST_DETAILS",
    GET_BEST_POSTS: "ACTION/POSTS/GET_BEST_POSTS",
    RESET_GET_BEST_POSTS: "ACTION/POSTS/RESET_GET_BEST_POSTS",
    GET_SIMILAR_POSTS: "ACTION/POSTS/GET_SIMILAR_POSTS",
    RESET_GET_SIMILAR_POSTS: "ACTION/POSTS/RESET_GET_SIMILAR_POSTS",
    FETCH_PUBLIC_ITEMS: "ACTION/POSTS/FETCH_PUBLIC_ITEMS",
    FETCH_PUBLIC_SERVICES: "ACTION/POSTS/FETCH_PUBLIC_SERVICES",
    RESET_PUBLIC_ITEMS: "ACTION/POSTS/RESET_PUBLIC_ITEMS",
    RESET_FETCH_PUBLIC_SERVICES: "ACTION/POSTS/RESET_FETCH_PUBLIC_SERVICES",
    REMOVE_FAVORITE_FROM_LIST: "ACTION/POSTS/REMOVE_FAVORITE_FROM_LIST",
    FAVORITE_POSTS: "ACTION/POSTS/FAVORITE_POSTS",
    RESET_FAVORITE_POSTS: "ACTION/POSTS/RESET_FAVORITE_POSTS",
    SAVE_POSITION: "ACTION/POSTS/SAVE_POSITION",
    RESET_SAVE_POSITION: "ACTION/POSTS/RESET_SAVE_POSITION",
  },
  BOOKING: {
    SET_BOOKING_DATA: "ACTION/BOOKING/SET_BOOKING_DATA",
    RESET_BOOKING_DATA: "ACTION/BOOKING/RESET_BOOKING_DATA",
    SET_BOOKING_POST: "ACTION/BOOKING/SET_BOOKING_POST",
    RESET_BOOKING_POST: "ACTION/BOOKING/RESET_BOOKING_POST",
    RESET_ALL: "ACTION/BOOKING/RESET_ALL",
  },
  ORDERS: {
    MY_ORDERS: "ACTION/ORDERS/MY_ORDERS",
    MY_ORDERS_POST_REVIEW: "ACTION/ORDERS/MY_ORDERS_POST_REVIEW",
    RESET_MY_ORDERS: "ACTION/ORDERS/RESET_MY_ORDERS",
    MY_BOOKINGS: "ACTION/ORDERS/MY_BOOKINGS",
    SINGLE_ORDER_DETAILS: "ACTION/ORDERS/SINGLE_ORDER_DETAILS",
    RESET_SINGLE_ORDER_DETAILS: "ACTION/ORDERS/RESET_SINGLE_ORDER_DETAILS",
    RESET_MY_BOOKINGS: "ACTION/ORDERS/RESET_MY_BOOKINGS",
    SAVE_POSITION: "ACTION/ORDERS/SAVE_POSITION",
    RESET_POSITION: "ACTION/ORDERS/RESET_POSITION",
    CANCEL_ORDER: "ACTION/ORDERS/CANCEL_ORDER",
    RESET_ALL: "ACTION/ORDERS/RESET_ALL",
  },
  CALENDAR: {
    GET_ITEM_CALENDAR_DETAILS: "ACTION/CALENDAR/GET_ITEM_CALENDAR_DETAILS",
    GET_SERVICE_CALENDAR_DETAILS: "ACTION/CALENDAR/GET_SERVICE_CALENDAR_DETAILS",
    ADD_EDIT_SERVICE_SLOT: "ACTION/CALENDAR/ADD_EDIT_SERVICE_SLOT",
    DELETE_SERVICE_SLOT: "ACTION/CALENDAR/DELETE_SERVICE_SLOT",
  },
  MESSAGE: {
    MY_MESSAGES: "ACTION/MESSAGE/MY_MESSAGES",
    SEND_MESSAGE: "ACTION/MESSAGE/SEND_MESSAGE",
    RESET_MESSAGES: "ACTION/MESSAGE/RESET_MESSAGES",
  },
  REVIEW: {
    LIST: "ACTION/REVIEW/LIST",
    LIST_RESET: "ACTION/REVIEW/LIST_RESET",
  },
  NOTIFICATION: {
    LIST: "ACTION/NOTIFICATION/LIST",
    UNREAD_COUNT: "ACTION/NOTIFICATION/UNREAD_COUNT",
    INC_UNREAD_COUNT: "ACTION/NOTIFICATION/INC_UNREAD_COUNT",
    MARK_READ: "ACTION/NOTIFICATION/MARK_READ",
    DELETE: "ACTION/NOTIFICATION/DELETE",
    ADD_NEW_NOTIFICATION: "ACTION/NOTIFICATION/ADD_NEW_NOTIFICATION",
    RESET_NOTIFICATIONS: "ACTION/NOTIFICATION/RESET_NOTIFICATIONS",
    RESET_ALL: "ACTION/NOTIFICATION/RESET_ALL",
  },
};

/**
 * saga actions for api calls
 */
export const SAGA_ACTIONS = {
  USER: {
    LOGIN: "SAGA_ACTION/USER/LOGIN",
    SOCIAL_LOGIN: "SAGA_ACTION/USER/SOCIAL_LOGIN",
    ME: "SAGA_ACTION/USER/ME",
    LOGOUT: "SAGA_ACTION/USER/LOGOUT",
    REGISTER: "SAGA_ACTION/USER/REGISTER",
    FORGOT_PASSWORD: "SAGA_ACTION/USER/FORGOT_PASSWORD",
    CHANGE_PASSWORD: "SAGA_ACTION/USER/CHANGE_PASSWORD",
    RESET_PASSWORD: "SAGA_ACTION/USER/RESET_PASSWORD",
    USER_INTEREST: "SAGA_ACTION/USER/USER_INTEREST",
    VERIFY_ACCOUNT: "SAGA_ACTION/USER/VERIFY_ACCOUNT",
    UPDATE_PROFILE: "SAGA_ACTION/USER/UPDATE_PROFILE",
    RESEND_VERIFICATION_EMAIL: "SAGA_ACTION/USER/RESEND_VERIFICATION_EMAIL",
    USER_BY_SLUG: "SAGA_ACTION/USER/USER_BY_SLUG",
    SAVED_CARDS: "SAGA_ACTION/USER/SAVED_CARDS",
    DELETE_CARD: "SAGA_ACTION/USER/DELETE_CARD",
    SAVE_BANK_ACCOUNT: "SAGA_ACTION/USER/SAVE_BANK_ACCOUNT",
    SAVE_IDENTITY_VERIFICATION: "SAGA_ACTION/USER/SAVE_IDENTITY_VERIFICATION",
  },
  CATEGORIES: {
    ALL_CATEGORIES_TREE: "SAGA_ACTION/CATEGORIES/ALL_CATEGORIES_TREE",
    GET_ITEM_CONDITIONS: "SAGA_ACTION/CATEGORIES/GET_ITEM_CONDITIONS",
  },
  CMS: {
    FETCH_HELP: "SAGA_ACTION/CMS/FETCH_HELP",
    FETCH_FAQ: "SAGA_ACTION/CMS/FETCH_FAQ",
  },
  FILE: {
    UPLOAD: "SAGA_ACTION/FILE/UPLOAD",
    LINK_FILE: "SAGA_ACTION/FILE/LINK_FILE",
    DELETE_FILE: "SAGA_ACTION/FILE/DELETE_FILE",
  },
  POST: {
    CREATE_POST: "SAGA_ACTION/POST/CREATE_POST",
    FETCH_ITEMS: "SAGA_ACTION/POST/FETCH_ITEMS",
    FETCH_SERVICES: "SAGA_ACTION/POST/FETCH_SERVICES",
    FETCH_MY_ITEMS: "SAGA_ACTION/POST/FETCH_MY_ITEMS",
    FETCH_MY_SERVICES: "SAGA_ACTION/POST/FETCH_MY_SERVICES",
    GET_POST_DETAILS: "SAGA_ACTION/POST/GET_POST_DETAILS",
    GET_BEST_POSTS: "SAGA_ACTION/POST/GET_BEST_POSTS",
    GET_SIMILAR_POSTS: "SAGA_ACTION/POST/GET_SIMILAR_POSTS",
    FETCH_PUBLIC_ITEMS: "SAGA_ACTION/POST/FETCH_PUBLIC_ITEMS",
    FETCH_PUBLIC_SERVICES: "SAGA_ACTION/POST/FETCH_PUBLIC_SERVICES",
    CHANGE_FAVORITE: "SAGA_ACTION/POST/CHANGE_FAVORITE",
    FAVORITE_POSTS: "SAGA_ACTION/POST/FAVORITE_POSTS",
    TOTAL_EARNINGS: "SAGA_ACTION/POST/TOTAL_EARNINGS",
  },
  BOOKING: {
    FETCH_SLOTS: "SAGA_ACTION/BOOKING/FETCH_SLOTS",
    BOOK_SLOT: "SAGA_ACTION/BOOKING/BOOK_SLOT",
    BOOK_ITEM: "SAGA_ACTION/BOOKING/BOOK_ITEM",
    CHECK_ITEM_AVAILABILITY: "SAGA_ACTION/BOOKING/CHECK_ITEM_AVAILABILITY",
    GET_ITEM_DISABLE_DATES: "SAGA_ACTION/BOOKING/GET_ITEM_DISABLE_DATES",
    PRICE_CALCULATE: "SAGA_ACTION/BOOKING/PRICE_CALCULATE",
  },
  ORDERS: {
    MY_ORDERS: "SAGA_ACTION/ORDERS/MY_ORDERS",
    MY_BOOKINGS: "SAGA_ACTION/ORDERS/MY_BOOKINGS",
    SINGLE_ORDER_DETAILS: "SAGA_ACTION/ORDERS/SINGLE_ORDER_DETAILS",
    CANCEL_ORDER: "SAGA_ACTION/ORDERS/CANCEL_ORDER",
    CALCULATE_CANCEL_FEE: "SAGA_ACTION/ORDERS/CALCULATE_CANCEL_FEE",
  },
  COMMON: {
    SETTINGS: "SAGA_ACTION/COMMON/SETTINGS",
    CONTACT_US: "SAGA_ACTION/COMMON/CONTACT_US",
  },
  CALENDAR: {
    GET_ITEM_CALENDAR_DETAILS: "SAGA_ACTION/CALENDAR/GET_ITEM_CALENDAR_DETAILS",
    UPDATE_ITEM_DAY_PRICE: "SAGA_ACTION/CALENDAR/UPDATE_ITEM_DAY_PRICE",
    GET_SERVICE_CALENDAR_DETAILS: "SAGA_ACTION/CALENDAR/GET_SERVICE_CALENDAR_DETAILS",
    ADD_EDIT_SERVICE_SLOT: "SAGA_ACTION/CALENDAR/ADD_EDIT_SERVICE_SLOT",
    DELETE_SERVICE_SLOT: "SAGA_ACTION/CALENDAR/DELETE_SERVICE_SLOT",
    GET_SLOT_ORDER_DETAILS: "SAGA_ACTION/CALENDAR/GET_SLOT_ORDER_DETAILS",
    ADD_BULK_SLOT: "SAGA_ACTION/CALENDAR/ADD_BULK_SLOT",
    CREATE_SLOT_DAY: "SAGA_ACTION/CALENDAR/CREATE_SLOT_DAY",
    DELETE_SLOT_DAY: "SAGA_ACTION/CALENDAR/DELETE_SLOT_DAY",
  },
  MESSAGE: {
    MY_MESSAGES: "SAGA_ACTION/MESSAGE/MY_MESSAGES",
    SEND_MESSAGE: "SAGA_ACTION/MESSAGE/SEND_MESSAGE",
    SET_READ: "SAGA_ACTION/MESSAGE/SET_READ",
  },
  REVIEW: {
    ADD_UPDATE: "SAGA_ACTION/REVIEW/ADD_UPDATE",
    LIST: "SAGA_ACTION/REVIEW/LIST",
  },
  NOTIFICATION: {
    LIST: "SAGA_ACTION/NOTIFICATION/LIST",
    UNREAD_COUNT: "SAGA_ACTION/NOTIFICATION/UNREAD_COUNT",
    MARK_READ: "SAGA_ACTION/NOTIFICATION/MARK_READ",
    DELETE: "SAGA_ACTION/NOTIFICATION/DELETE",
  },
};

/**
 * app urls
 */
export const URLS = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  REGISTER: "/register",
  REGISTER_SUCCESS: "/registration-success",
  PAYMENT_SUCCESS: "/payment-success",
  HOME: "/",
  PRIVACY: "/privacy-policy",
  TERMS_CONDITION: "/terms-and-conditions",
  HELP: "/help",
  ABOUT_US: "/about-us",
  MY_PROFILE: "/my-profile",
  PROFILE: "/profile",
  FAQ: "/faq",
  CONTACT_US: "/contact-us",
  ITEMS: "/items",
  SERVICES: "/services",
  USER_INTEREST: "/user-interest",
  CHECKOUT: "/checkout",
  CREATE_POST: "/create-post",
  EDIT_POST: "/edit-post",
  EDIT_PROFILE: "/edit-profile",
  FAVORITES: "/favorites",
  ITEM_DETAILS: "/item-details",
  MESSAGE: "/message",
  MY_ORDERS: "/my-orders",
  NOTIFICATIONS: "/notifications",
  SERVICE_DETAILS: "/service-details",
  VERIFY_ACCOUNT: "/verify-account",
  MY_POSTS: "/my-posts",
  MY_BOOKINGS: "/my-bookings",
  BOOKING_CALENDAR: "/booking-calendar",
  BOOKING_HISTORY: "/booking-history",
};

/**
 * notification related settings
 */
export const NOTIFICATION_SETTINGS = {
  /**
   * pusher channel name on which notification event is published
   */
  PUSHER_CHANNEL: "notification",
  /**
   * pusher event name, on which new notification is published
   */
  PUSHER_EVENT: "notification",
  /**
   * interval in milliseconds, determines the interval for regular
   * calls to fetch latest notification count, bellow is set to 10 minutes
   */
  FETCH_INTERVAL: 1000 * 60 * 0.5,
};

/**
 * different notifications types
 */
export const NOTIFICATION_TYPES = {
  NEW_MESSAGE: "newMessage", // new message has been received
  BOOK_REMINDER: "bookReminder", // reminder for booking
  CANCEL_BOOKING: "cancelBooking", // if booking is cancelled
  BOOK_CONFIRMED: "bookConfirmed", // once booking is confirmed
  REVIEW_REMINDER: "reviewReminder", // reminder to give review
};

export const SITE_NAME = "Rentmie";
export const SITE_URL = "http://rentmie.glohtesting.com/";
export const SITE_LANGUAGE = "EN-us";
export const SITE_LOGO = "/img/logo.png";
export const DEFAULT_USER_IMAGE = "/img/default_user.png"; // default user placeholder image

/**
 * regex used for validation of file types in create post/edit post
 */
export const REGEX_PATTERNS = {
  image: new RegExp(
    "(\\.jpg|\\.jpeg|\\.bmp|\\.gif|\\.png|\\.tif|\\.tiff|\\.apng|\\.ico|\\.cur|\\.jfif|\\.pjpeg|\\.pjpeg|\\.svg|\\.webp|\\.xxxx|\\.xxxx)$",
    "i"
  ),
  video: new RegExp("(\\.mp4|\\.webm|\\.3gp|\\.ogg|\\.m4a|\\.m4v|\\.3gp2|\\.wmv|\\.flv|\\.mov)$", "i"),
  audio: new RegExp(
    "(\\.mp3|\\.aac|\\.bmp|\\.gif|\\.png|\\.ogg|\\.mp4|\\.3gp|\\.mpeg|\\.mov|\\.mpeg|\\.m4a|\\.wma|\\.flv|\\.avi)$",
    "i"
  ),
};

/**
 * post_type
 */
export const CATEGORY_STATIC = {
  SERVICE: 2,
  ITEM: 1,
  IS_FEATURED: 1,
};

/**
 * Extract error message nested in response object from api call
 * @param {*} error The Error
 * @param {*} defaultMessage The default message
 */
export const extractErrorMessage = (error, defaultMessage = "Please try again") => {
  if (typeof error === "string") {
    return error;
  } else {
    if (error && error.data && error.data.errors && Object.keys(error.data.errors).length) {
      return error.data.errors[Object.keys(error.data.errors)[0]];
    } else if (error && error.data && error.data.message) {
      return error.data.message;
    } else if (error && error.message) {
      return error.message;
    } else {
      return defaultMessage;
    }
  }
};

/**
 * Check whether the user is allowed to create post
 * conditions are
 * 1. user's profile must be completed
 * 2. User's bank account should be verified
 * 3. User identity verification must be completed
 * Todo: We may have to stop checking identity
 * ? Have to check in future once client confirms
 * 4. User's preference must be set to 0 (looking_rentals)
 * 5. User's email must be verified
 * @param {*} user User
 */
export const checkIfUserAllowedToPost = (user) => {
  if (!user) {
    return false;
  }
  if (
    user.bank_verification != 1 ||
    user.identity_verification != 1 ||
    !user.profile ||
    !user.profile.is_complete ||
    user.looking_rentals == 1 ||
    !user.email_verified_at
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * Check if the listing api has more items to provide
 * ? check whether the current page is equal to last page -> provided from api
 * @param {*} data Object received from listing apis
 */
export const checkIfHasMoreItem = (data) => {
  if (!data || !data.current_page || !data.last_page || !data.data || !data.data.length) {
    return false;
  }
  return data.current_page < data.last_page;
};

/**
 * merge old and new data set, mainly used in listing apis where we have used
 * pagination (server side)
 * @param {*} oldData Old Data set
 * @param {*} newData New Data Set
 */
export const mergeDataSet = (oldData, newData) => {
  if (!oldData && !newData) {
    return null;
  }
  if (!oldData) {
    return newData;
  }
  if (!newData) {
    return oldData;
  }
  return {
    ...newData,
    data: oldData.data.concat(newData.data),
  };
};

/**
 * form a final url from the main url and the params passed as object
 * @param {*} url Main URL
 * @param {*} params Params object
 */
export const formUrlFromParamObject = (url, params) => {
  if (!url) {
    return null;
  }
  if (!params || Object.keys(params).length == 0) {
    return url;
  }

  let keys = Object.keys(params);

  let paramString = keys
    .map((x) => {
      return `${x}=${params[x]}`;
    })
    .join("&");
  return `${url}?${paramString}`;
};

/**
 * cost type for items
 */
export const COST_TYPES = {
  1: "day",
  2: "week",
  3: "month",
  4: "year",
};
/**
 * cost types for items for use in select field
 */
export const COST_TYPES_OPTIONS = [
  { value: "1", label: "Per Day" },
  { value: "2", label: "Per Week" },
  { value: "3", label: "Per Month" },
  { value: "4", label: "Per Year" },
];
/**
 * item delivery type
 */
export const DELIVERY_TYPES = [
  { value: "1", label: "Delivery", slug: "delivery" },
  { value: "2", label: "Pickup", slug: "pickup" },
];
/**
 * service availability types
 */
export const SERVICE_AVAILED_AT = [
  { value: "1", label: "Customer Site", slug: "customer-site" },
  { value: "2", label: "Your Site", slug: "your-site" },
];
/**
 * item orders, for use in listing item/service pages
 * value -> sent to api
 * order -> on which the sorting should work
 * sort -> whether ascending or descending
 * isDefault -> must be only one, default order selected while loading listing UIs
 */
export const ITEM_ORDERS = [
  { name: "Popularity", value: "popularity", order: "id", sort: "desc", isDefault: false },
  { name: "Latest Addition", value: "latest", order: "id", sort: "desc", isDefault: false },
  { name: "Price Low to High", value: "price-lh", order: "cost", sort: "asc", isDefault: true },
  { name: "Price High to Low", value: "price-hl", order: "cost", sort: "desc", isDefault: false },
];

/**
 * image size while uploading images in create/edit post
 */
export const IMAGE_MIN_MULTIPLIER = 100;
export const IMAGE_RATIO_WIDTH = 7; // width ratio
export const IMAGE_RATIO_HEIGHT = 4; // height ratio

/**
 * min size (height/width) required for uploading images
 */
export const IMAGE_VALIDATION = {
  WIDTH: 763,
  HEIGHT: 436,
};
/**
 * aspect ratio related calculations
 */
export const POST_IMAGE_ASPECT_RATIO = {
  WIDTH: IMAGE_RATIO_WIDTH,
  HEIGHT: IMAGE_RATIO_HEIGHT,
  MIN_HEIGHT: IMAGE_RATIO_HEIGHT * IMAGE_MIN_MULTIPLIER,
  MIN_WIDTH: IMAGE_RATIO_WIDTH * IMAGE_MIN_MULTIPLIER,
};

export const IMAGE_ASPECT_RATIO = IMAGE_RATIO_WIDTH / IMAGE_RATIO_HEIGHT;

/**
 * file size validation limits (in mb)
 */

export const FILE_SIZE_LIMITS = {
  IMAGE: 10,
  VIDEO: 200,
};

/**
 * Get byte converted into Megabyte (B -> MB)
 * @param {number} bytes Input byte
 */
export const getMBFromBytes = (bytes) => {
  if (!bytes || !isNumber(bytes)) {
    return null;
  }
  return parseFloat((bytes / 1e6).toFixed(2));
};

/**
 * dev pusher config, required for messages and notifications
 */
let pusherConfig = {
  app_id: "1077223",
  key: "4627396c5a59c44ec3bc",
  secret: "d2dac8612761cdc02775",
  cluster: "us2",
};
/**
 * stage/live pusher config
 */
if (process.env.REACT_APP_STAGE === "stage" || process.env.REACT_APP_STAGE === "prod") {
  pusherConfig = {
    app_id: "1077225",
    key: "4dc52940fe670eb5e498",
    secret: "d417d80e73c69b83e9f6",
    cluster: "us2",
  };
}
export const PUSHER_CONFIG = pusherConfig;

/**
 * google and facebook app ids for social login
 */
export const SOCIAL_LOGIN_APPS = {
  GOOGLE: {
    APP_ID: "418771708721-m8a3edl20mbduu48d8lvjrunpa7chp1d.apps.googleusercontent.com",
    SCOPES: ["https://www.googleapis.com/auth/userinfo.email"],
  },
  FACEBOOK: {
    APP_ID: "2677573175829359",
    SCOPES: [],
  },
};

export const checkIfDateTimeOngoing = (date, startTime, endTime) => {
  let now = moment();
  let start = moment(`${date} ${startTime}`, "YYYY-MM-DD HH:mm:ss");
  let end = moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm:ss");
  return now.isBetween(start, end);
};

/**
 * google site key for recaptcha
 * TODO: Must check whether it's from personal account
 * ? it should be from client account
 */
export const GOOGLE_RECAPTCHA_SITE_KEY = "6LcnjcIZAAAAAOkVbyLyygzlB5bgSTVtE41NuLU4";

/**
 * USA state list
 */
export const USA_STATES = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];
