import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { actions as toastrActions } from 'react-redux-toastr';
import { toastr } from 'react-redux-toastr'
import { bindActionCreators } from 'redux';
import { ACTIONS, SAGA_ACTIONS, URLS, NOTIFICATION_SETTINGS, extractErrorMessage } from '../../../config/config';
import Pusher from 'react-pusher';

import classnames from 'classnames'
import Img from '../../_common/components/img'
import { getNotificationActionUrl } from './common.func';

class NotificationIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
    this.toastrIds = []
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
    this.fetchCount = this.fetchCount.bind(this);
    this.onPusherUpdate = this.onPusherUpdate.bind(this);
  }
  componentDidMount() {
    this.props.doResetNotificationAll();
    this.fetchCount();
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    this.timer = setInterval(this.fetchCount, NOTIFICATION_SETTINGS.FETCH_INTERVAL)
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  fetchCount() {
    this.props.doFetchUnreadCount({}, (_) => {}, (_) => {})
  }

  onPusherUpdate(notification) {
    this.fetchCount();
    if (window.location.pathname === URLS.NOTIFICATIONS) {
      this.props.doAddNewNotification(notification)
    } else {
      this.props.doIncreaseNotificationCount();
      // show toaster
      toastr.info('New Notification Received', notification.text, {
        showCloseButton: true,
        position: this.props.isDesktop ? 'top-left' : 'top-center',
        attention: true,
        onAttentionClick: id => {},
        onToastrClick: () => {
          toastr.removeByType('info');
          let url = getNotificationActionUrl(notification, this.props.user)
          this.props.history.push(url);
        },
      })
    }
  }
  
  showToaster(type, title, message, onClickFunc) {
    let id = this.toastr.add({
      type,
      title,
      position: this.props.isDesktop ? 'top-left' : 'top-center',
      attention: true,
      onAttentionClick: id => {},
      message,
      options: {
        showCloseButton: true,
        onToastrClick: onClickFunc ? onClickFunc : () => {}
      },
      showCloseButton: true,
    });
  }
  render() {
    const { count, user, isMobile } = this.props
    const eventName = user && user.slug ? `event_${user.slug}` : null
    const cntStr = count <= 0 ? null : count > 9 ? '9+' : String(count)
    return (
      <>
        <li className="notification-nav-link">
          <Link to={URLS.NOTIFICATIONS}>
            <img src={isMobile ? 'img/notification-icon.png' : 'img/notification-icon-pink.png'} alt="" />
            {
              cntStr ? <span>{cntStr}</span> : null
            }
          </Link>
        </li>
        {
          eventName ? <Pusher
            channel={NOTIFICATION_SETTINGS.PUSHER_CHANNEL}
            event={eventName}
            onUpdate={this.onPusherUpdate}
          /> : null
        }
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
    ...state.responsive,
    count: state.notification.unreadCount,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showLoader: () => dispatch({
    type: ACTIONS.SHOW_LOADER,
  }),
  hideLoader: () => dispatch({
    type: ACTIONS.HIDE_LOADER,
  }),
  doResetNotificationAll: () => dispatch({
    type: ACTIONS.NOTIFICATION.RESET_ALL,
  }),
  doResetNotification: () => dispatch({
    type: ACTIONS.NOTIFICATION.RESET_NOTIFICATIONS,
  }),
  doIncreaseNotificationCount: () => dispatch({
    type: ACTIONS.NOTIFICATION.INC_UNREAD_COUNT,
  }),
  doAddNewNotification: (payload) => dispatch({
    type: ACTIONS.NOTIFICATION.ADD_NEW_NOTIFICATION,
    payload,
  }),
  doFetchUnreadCount: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.NOTIFICATION.UNREAD_COUNT,
    payload,
    callbackSuccess,
    callbackError,
  }),
  doFetchNotifications: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.NOTIFICATION.LIST,
    payload,
    callbackSuccess,
    callbackError,
  }),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationIndicator));