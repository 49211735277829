import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { URLS } from '../../config/config';
import HelmetSection from './components/helmet.section'
import Img from './components/img'

class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <HelmetSection title="Rentmie: Invalid URL" />
        <section className="rental-history-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12" style={{
                minHeight: 'calc(100vh - 330px)'
              }}>
                <div className="text-center pt-3 pb-3"><Img src="img/404-error.jpg" /></div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps)(Page404);