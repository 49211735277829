/**
 *
 * Img.js
 *
 * Renders an image, enforcing the usage of the alt="" tag
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Img as RNImage} from 'react-image';

class Image extends React.Component {
  render() {
    let props = this.props
    return (
      <RNImage
        className={props.className}
        src={props.src}
        alt={props.alt}
        loader={<div className="image-loading text-center">
          <i className="fa fa-spinner fa-spin"/>
        </div>}
        unloader={<div className="image-not-found">
          <img src="img/noimage.jpg" alt="Not available"/>
        </div>}
      />
    );
  }
}

export default Image;
