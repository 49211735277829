import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const PaymentSuccessLoader = Loadable({
    loader: () => import('./paymentSuccess'),
    loading: () => <ComponentLoader/>,
});

const PaymentSuccess = () => (
    <PaymentSuccessLoader/>
);

export default PaymentSuccess;