import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route, withRouter, HashRouter } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import './common/styles/slick.css';
import './common/styles/slick-theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.css';

import requireAuth from './common/hoc/reqAuth';
import requireNoAuth from './common/hoc/reqNoAuth';
import openRoutes from './common/hoc/openAuth';
import { URLS, ACTIONS, IS_IN_MAINTANCE, SAGA_ACTIONS } from './config/config';

import Header from './pages/_common/components/header'
import Footer from './pages/_common/components/footer'

import Login from './pages/login'
import Registration from './pages/registration'
import Home from './pages/home'
import Profile from './pages/profile'
import Page404 from './pages/_common/page404'
import About from './pages/about'
import Checkout from './pages/checkout'
import ContactUs from './pages/contactus'
import CreatePost from './pages/create-post'
import EditProfile from './pages/edit-profile'
import Faq from './pages/faq/faq'
import Favorites from './pages/favorites'
import ForgetPassword from './pages/forget-password'
import Help from './pages/help'
import ItemDetails from './pages/item-details'
import Items from './pages/items'
import Message from './pages/messages'
import MyOrders from './pages/myorders'
import Notifications from './pages/notifications'
import Privacy from './pages/privacy'
import ResetPassword from './pages/reset-password'
import ServiceDetails from './pages/service-details'
import Services from './pages/services'
import Terms from './pages/terms'
import UserInterest from './pages/user-interest'
import VerifyAccount from './pages/verify-account'
import MyPosts from './pages/myposts'
import RegistrationSuccess from './pages/registration-success'
import MyBookings from './pages/mybookings'
import BookingCalendar from './pages/booking-calendar'
import EditPost from './pages/edit-post'
import PaymentSuccess from './pages/payment-success'

class App extends React.Component {
  routeStorage = [];
  routes = {
    public: [{
      name: URLS.LOGIN,
      component: Login
    }, {
      name: URLS.REGISTER,
      component: Registration,
    }, {
      name: URLS.REGISTER_SUCCESS,
      component: RegistrationSuccess,
    }, {
      name: URLS.FORGOT_PASSWORD,
      component: ForgetPassword,
    }, {
      name: URLS.RESET_PASSWORD,
      component: ResetPassword,
    }],
    open: [{
      name: URLS.HOME,
      component: Home,
    }, {
      name: URLS.VERIFY_ACCOUNT,
      component: VerifyAccount,
    }, {
      name: URLS.PROFILE,
      component: Profile
    }, {
      name: URLS.ABOUT_US,
      component: About,
    }, {
      name: URLS.CHECKOUT,
      component: Checkout,
    }, {
      name: URLS.CONTACT_US,
      component: ContactUs,
    }, {
      name: URLS.FAQ,
      component: Faq,
    }, {
      name: URLS.HELP,
      component: Help,
    }, {
      name: URLS.ITEM_DETAILS,
      component: ItemDetails,
    }, {
      name: URLS.ITEMS,
      component: Items,
    }, {
      name: URLS.SERVICES,
      component: Services,
    }, {
      name: URLS.SERVICE_DETAILS,
      component: ServiceDetails,
    }, {
      name: URLS.PRIVACY,
      component: Privacy,
    }, {
      name: URLS.TERMS_CONDITION,
      component: Terms,
    }],
    private: [{
      name: URLS.CREATE_POST,
      component: CreatePost,
      reqPostPermission: true
    }, {
      name: URLS.EDIT_POST,
      component: EditPost
    }, {
      name: URLS.EDIT_PROFILE,
      component: EditProfile,
    }, {
      name: URLS.FAVORITES,
      component: Favorites
    }, {
      name: URLS.MESSAGE,
      component: Message,
    }, {
      name: URLS.MY_ORDERS,
      component: MyOrders,
    }, {
      name: URLS.NOTIFICATIONS,
      component: Notifications,
    }, {
      name: URLS.USER_INTEREST,
      component: UserInterest,
    }, {
      name: URLS.MY_PROFILE,
      component: Profile,
    }, {
      name: URLS.MY_POSTS,
      component: MyPosts,
    }, {
      name: URLS.MY_BOOKINGS,
      component: MyBookings,
      reqPostPermission: true
    }, {
      name: URLS.BOOKING_HISTORY,
      component: MyBookings,
      reqPostPermission: true
    }, {
      name: URLS.BOOKING_CALENDAR,
      component: BookingCalendar,
    }, {
      name: URLS.PAYMENT_SUCCESS,
      component: PaymentSuccess
    }]
  };
  constructor(props, context) {
    super(props, context);

    this.pushRoute = this.pushRoute.bind(this);
    this.popRoute = this.popRoute.bind(this);
    this.getRouteStorage = this.getRouteStorage.bind(this);
    this.clearRouteStorage = this.clearRouteStorage.bind(this);
    this.getFirstPublicRoute = this.getFirstPublicRoute.bind(this);
    this.getFirstPrivateRoute = this.getFirstPrivateRoute.bind(this);
    this.checkScreenWidth = this.checkScreenWidth.bind(this);

    this.state = {
      showModals: false
    }

    setTimeout(() => {
      this.checkScreenWidth();
    }, 0)
  }

  componentWillMount() {
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkScreenWidth)

    this.props.doGetCategories({}, () => {}, () => {});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth)
  }

  checkScreenWidth() {
    if (!this.state.showModals) {
      this.setState({ showModals: true })
    }
    const { isDesktop, isTab, isMobile } = this.props
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

    if (windowWidth > 0 && windowWidth < 768) {
      // is mobile
      if (!isMobile) {
        this.props.dispatch({
          type: ACTIONS.RESPONSIVE.SET_MOBILE
        })
      }
    } else if (windowWidth >= 768 && windowWidth < 992) {
      // is tab
      if (!isTab) {
        this.props.dispatch({
          type: ACTIONS.RESPONSIVE.SET_TAB
        })
      }
    } else {
      // is desktop
      if (!isDesktop) {
        this.props.dispatch({
          type: ACTIONS.RESPONSIVE.SET_DESKTOP
        })
      }
    }
  }

  pushRoute(route) {
    if (this.routeStorage.indexOf(route) < 0) {
      if (this.routeStorage.length > 5) {
        this.routeStorage.pop();
      }
      this.routeStorage.push(route);
    }
  }
  popRoute(route) {
    if (!this.routeStorage.length) {
      return null;
    }
    return this.routeStorage.pop();
  }
  getRouteStorage() {
    return this.routeStorage;
  }
  clearRouteStorage() {
    this.routeStorage = [];
  }
  getFirstPublicRoute() {
    let pubr = this.routes.public.map(x => x.name);
    let ret = URLS.HOME;
    for (let i = 0; i < this.routeStorage.length; i++) {
      if (pubr.findIndex(x => this.routeStorage[i].startsWith(x)) >= 0) {
        ret = this.routeStorage[i];
        if (ret.indexOf('?') >= 0) {
          ret = ret.slice(0, ret.indexOf('?'));
        }
        break;
      }
    }
    return ret;
  }

  getFirstPrivateRoute() {
    let prr = this.routes.private.map(x => x.name);
    let ret = URLS.HOME;
    for (let i = 0; i < this.routeStorage.length; i++) {
      if (prr.findIndex(x => this.routeStorage[i].startsWith(x)) >= 0) {
        ret = this.routeStorage[i];
        break;
      }
    }
    return ret;
  }

  render() {
    const func = {
      pushRoute: this.pushRoute,
      popRoute: this.popRoute,
      getRouteStorage: this.getRouteStorage,
      clearRouteStorage: this.clearRouteStorage,
      getFirstPrivateRoute: this.getFirstPrivateRoute,
      getFirstPublicRoute: this.getFirstPublicRoute,
    }
    return (
      <>
        <Router>
          <Header />
          <div className="App height-full">
            <div className="min-h-main">
              <Switch>
                <Route exact path="/" component={Home} />
                {
                  this.routes && this.routes.public.map((x, index) => {
                    return <Route exact key={index} path={x.name} component={requireNoAuth(x.component, func)} />
                  })
                }

                {
                  this.routes && this.routes.open.map((x, index) => {
                    return <Route exact key={index} path={x.name} component={openRoutes(x.component, func)} />
                  })
                }

                {
                  this.routes && this.routes.private.map((x, index) => {
                    return <Route exact key={index} path={x.name} component={requireAuth(x.component, func)} />
                  })
                }

                <Route path="*" component={Page404} />
              </Switch>
            </div>
          </div>
          <Footer />
        </Router>
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    authenticated: state.user.isAuthenticated,
    ...state.responsive,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showLoader: () => dispatch({
    type: ACTIONS.SHOW_LOADER,
  }),
  hideLoader: () => dispatch({
    type: ACTIONS.HIDE_LOADER,
  }),
  doGetCategories: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.CATEGORIES.ALL_CATEGORIES_TREE,
    payload,
    callbackSuccess,
    callbackError,
  }),
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
