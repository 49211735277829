import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { actions as toastrActions } from 'react-redux-toastr';

import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import classnames from 'classnames'
import Img from './img'
import {
  InputField,
  DatepickerField,
  SelectField,
} from '../../../common/form-elements/elements'

import './homeBannerSearch.css';

import {
  required,
  required_select,
  required_custom_select,
  requiredDate,
} from '../../../common/validators/form-validators'
import { extractErrorMessage, ACTIONS, SAGA_ACTIONS, URLS, formUrlFromParamObject } from '../../../config/config';

class HomeBannerSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.categoryChanged = this.categoryChanged.bind(this);
    this.change = bindActionCreators(change, this.props.dispatch);
    this.handleSubmitFunc = this.handleSubmitFunc.bind(this);
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }
  componentDidMount() {
    this.props.doGetCategories({}, (success) => {
    }, (error) => {
      this.showToaster('error', 'Unable to load categories', extractErrorMessage(error));
    })
  }
  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: this.props.isDesktop ? 'top-left' : 'top-center',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }
  categoryChanged(event, newValue, previousValue, name) {
    this.change('homeSearchForm', 'sub_category_id', null);
  }
  handleSubmitFunc(values) {
    let params = {}
    let url = this.props.url;
    
    if (values && values.category_id && values.category_id.value) {
      params.category = values.category_id.value
    }
    if (values && values.sub_category_id && values.sub_category_id.value) {
      params.subcategory = values.sub_category_id.value
    }
    if (values && values.address) {
      params.address = values.address
    }
    if (values && values.from) {
      params.from = moment(values.from).format('MM-DD-YYYY')
    }
    if (values && values.till) {
      params.till = moment(values.till).format('MM-DD-YYYY')
    }

    this.props.history.push(formUrlFromParamObject(url, params));
  }
  render() {
    const {
      handleSubmit,
      error,
      invalid,
      submitting,
      errors
    } = this.props;
    return (
      <>
        <form name="homeSearchForm" onSubmit={handleSubmit(this.handleSubmitFunc)}>
          <div className="row">
            <div className="col">
              <label>Categories</label>
              <Field name="category_id" component={SelectField} placeholder="" options={this.props.categories} classNamePrefix="home-search-select" onChange={this.categoryChanged} />
            </div>
            <div className="col">
              <label>Subcategories</label>
              <Field name="sub_category_id" classNamePrefix="home-search-select" component={SelectField} placeholder="" options={this.props.subCategories} />
            </div>
            <div className="col col-custom-50">
              <label>Start</label>
              <div className="search-date-fld">
                <Field name="from" component={DatepickerField} className="d-block form-control home-search-date" placeholder="mm/dd/yyyy" minDate={moment().toDate()} />
              </div>
            </div>
            <div className="col col-custom-50">
              <label>End</label>
              <div className="search-date-fld">
                <Field name="till" component={DatepickerField} className="d-block form-control home-search-date" placeholder="mm/dd/yyyy" minDate={moment().toDate()} />
              </div>
            </div>
            <div className="col">
              <label>City Or Zip Code</label>
              <Field name="address" component={InputField} className="form-control" placeholder="" />
            </div>
            <div className="col btn-col">
              <label>&nbsp;</label>
              <button type="submit" className="more-btn-solid">Search</button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

const HomeBannerSearchFormComponent = reduxForm({
  form: 'homeSearchForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate(values) {
    let errors = {}
    return errors
  }
})(HomeBannerSearchForm)

const transformCategories = (data) => {
  if (!data || !data.length) {
    return []
  }
  return data.map(x => ({
    value: "" + x.slug,
    label: x.name,
  }))
}

const getSubCategories = (form, categories) => {
  if (!form || !form.values || !form.values.category_id || !form.values.category_id.value || !categories || !categories.length) {
    return [];
  }

  let cat = categories.find(x => x.slug == form.values.category_id.value)

  return cat && cat.sub_category && cat.sub_category.length ? cat.sub_category : []
}


const mapStateToProps = (state, props) => {
  const allCategories = props && props.url == URLS.ITEMS ? state.category.itemCategories : state.category.serviceCategories
  const subCategories = transformCategories(getSubCategories(state.form.homeSearchForm, state.category.itemCategories))
  return {
    user: state.user.user,
    categories: transformCategories(allCategories),
    subCategories,
    allCategories,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showLoader: () => dispatch({
    type: ACTIONS.SHOW_LOADER,
  }),
  hideLoader: () => dispatch({
    type: ACTIONS.HIDE_LOADER,
  }),
  doGetCategories: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.CATEGORIES.ALL_CATEGORIES_TREE,
    payload,
    callbackSuccess,
    callbackError,
  }),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeBannerSearchFormComponent));