import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const BookingCalendarLoader = Loadable({
    loader: () => import('./bookingCalendar'),
    loading: () => <ComponentLoader/>,
});

const BookingCalendar = () => (
    <BookingCalendarLoader/>
);

export default BookingCalendar;