import { ACTIONS } from "../../config/config";

const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  userPublic: null,
  cards: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.access_token,
        user: action.payload.user,
      }
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
        cards: null,
      }
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: {
          ...action.payload
        },
        isAuthenticated: true,
      }
    case ACTIONS.USER.USER_BY_SLUG:
      return {
        ...state,
        userPublic: action.payload,
      }
    case ACTIONS.USER.SAVED_CARDS:
      return {
        ...state,
        cards: action.payload,
      }
    default:
      return state;
  }
}
export default userReducer;
