import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const MyBookingsLoader = Loadable({
    loader: () => import('./myBookings'),
    loading: () => <ComponentLoader/>,
});

const MyBookings = () => (
    <MyBookingsLoader/>
);

export default MyBookings;