import { URLS, NOTIFICATION_TYPES } from "../../../config/config"

export const getNotificationActionUrl = (notification, user) => {
  if (!notification || !notification.type) {
    return URLS.HOME
  }
  let url = ''
  const modifiedOrderId = notification.order && notification.order.order_id ? notification.order.order_id.replace(/#/g, '') : ''
  const orderId = notification.order && notification.order.order_id ? notification.order.order_id : ''
  const isCurrentUserUploader = user && user.id && notification.post && notification.post.user_id == user.id
  switch (notification.type) {
    case NOTIFICATION_TYPES.NEW_MESSAGE:
      url = `${URLS.MESSAGE}?order=${modifiedOrderId}&redirect=${isCurrentUserUploader ? URLS.MY_BOOKINGS : URLS.MY_ORDERS}`
      break;
    case NOTIFICATION_TYPES.BOOK_CONFIRMED:
    case NOTIFICATION_TYPES.CANCEL_BOOKING:
      url = isCurrentUserUploader ? `${URLS.MY_BOOKINGS}?highlight=${modifiedOrderId}` : `${URLS.MY_ORDERS}?highlight=${modifiedOrderId}`
      break;
    case NOTIFICATION_TYPES.BOOK_REMINDER:
      url = `${URLS.MY_ORDERS}?highlight=${modifiedOrderId}`
      break;
    case NOTIFICATION_TYPES.REVIEW_REMINDER:
      url = `${URLS.MY_ORDERS}?highlight=${modifiedOrderId}&open=review`
      break;
    default:
      url = URLS.HOME
  }
  return url;
}