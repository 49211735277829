import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const PrivacyLoader = Loadable({
    loader: () => import('./privacy'),
    loading: () => <ComponentLoader/>,
});

const Privacy = () => (
    <PrivacyLoader/>
);

export default Privacy;