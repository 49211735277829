import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}
export function* sendMessage(action) {
  const showMessage = false
  try {
    const data = action.payload;
    showMessage && (yield setLoader(true))
    const resp = yield call(CallApi.POST, APIS.MESSAGE.SEND_MESSAGE, data, true);
    showMessage && (yield setLoader(false))
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    showMessage && (yield setLoader(false))
    action && action.callbackError && action.callbackError(e);
  }
}
export function* listMessages(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.MESSAGE.MY_MESSAGES, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.MESSAGE.MY_MESSAGES,
        payload: resp.data.data,
        query: data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* setMessageStatus(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, APIS.MESSAGE.SET_READ, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e);
  }
}