import { ACTIONS } from '../../config/config';

const initialState = {
  shouldShowRegSuccess: false,
  shouldShowPaymentSuccess: false,
  settings: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.COMMON.SET_REG_SUCCESS:
      return {
        ...state,
        shouldShowRegSuccess: action.payload,
      };
    case ACTIONS.COMMON.SET_PAYMENT_SUCCESS:
      return {
        ...state,
        shouldShowPaymentSuccess: action.payload,
      };
    case ACTIONS.COMMON.SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
