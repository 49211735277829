import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select'
import ReCAPTCHA from "react-google-recaptcha";
import { RadioGroup, RadioButton } from 'react-radio-buttons'
import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays'
import InputRange from 'react-input-range';
import ReactRating from 'react-rating'

import {
  Dropdown,
} from 'react-bootstrap'

import 'react-input-range/lib/css/index.css';
import "react-datepicker/dist/react-datepicker.css";
import './elements.css';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../config/config';

export const formErrorStyle = {
  fontSize: '12px',
  color: '#f00',
  display: 'inline-block',
};

/**
 * Normal input field, can be used for password, email and normal text input
 * @param {*} param0 
 */
export const InputField = ({
  input,
  className,
  name,
  id,
  readOnly,
  label,
  placeholder,
  value,
  type,
  style,
  autoComplete,
  disabled,
  checked,
  meta: { touched, error, warning },
}) => (
    <>
      <Form.Control
        {...input}
        type={type}
        id={id}
        style={style}
        placeholder={placeholder}
        className={className}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
        checked={checked}
        invalid={error && touched ? "invalid" : undefined}
      />

      {touched &&
        (error && (
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
        ))}
    </>
  );

/**
 * Text area input field
 * @param {*} param0 
 */
export const TextAreaField = ({
  input,
  className,
  name,
  id,
  readOnly,
  label,
  placeholder,
  value,
  type,
  row,
  col,
  style,
  autoComplete,
  disabled,
  checked,
  meta: { touched, error, warning },
}) => (
    <>
      <textarea
        {...input}
        type={type}
        id={id}
        rows={row}
        cols={col}
        style={style}
        placeholder={placeholder}
        className={className}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
        checked={checked}
        invalid={error && touched ? "invalid" : undefined}
      ></textarea>

      {touched &&
        (error && (
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
        ))}
    </>
  );

/**
 * Select2/react input field, can be customized
 * for multi select
 */
export class SelectField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      input: { value, onChange, name },
      placeholder,
      disabled,
      readOnly,
      options,
      isMulti,
      classNamePrefix,
      isOptionDisabled,
      meta: { touched, error }
    } = this.props
    const disableFunc = isOptionDisabled ? isOptionDisabled : (option) => {
      return false
    }
    return (
      <>
        <Select
          classNamePrefix={classNamePrefix || 'rentmie-select-custom'}
          disabled={disabled}
          readOnly={readOnly}
          options={options}
          closeMenuOnSelect={!isMulti}
          value={value}
          inputProps={{ autoComplete: 'new-random-string', autoFill: 'off' }}
          isMulti={isMulti}
          placeholder={placeholder}
          isOptionDisabled={disableFunc}
          onChange={(sel) => this.props.input.onChange(sel)}
        />
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}

/**
 * radio button field
 */
export class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      input: { value, onChange, name },
      placeholder,
      disabled,
      readOnly,
      meta: { touched, error },
      isHorizontal,
      options,
      iconSize,
      iconInnerSize,
      rootColor,
      pointColor,
    } = this.props
    return (
      <>
        <RadioGroup className="rentmie-custom-radio" onChange={onChange} value={value} horizontal={isHorizontal}>
          {
            options.map((x, i) => {
              return <RadioButton
                value={x.value}
                key={i}
                iconSize={iconSize || 20}
                iconInnerSize={iconInnerSize || 10}
                rootColor={rootColor || "#dc104a"}
                pointColor={pointColor || "#dc104a"}
              >
                {x.label}
              </RadioButton>
            })
          }
        </RadioGroup>
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}

/**
 * google recaptcha field
 */
export class ReCaptchaField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      input: { value, onChange, name },
      placeholder,
      disabled,
      readOnly,
      meta: { touched, error },
      isHorizontal,
      options,
    } = this.props
    return (
      <>
        <ReCAPTCHA
          sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={onChange}
        />
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}

// const CalendarContainer = ({children}) => {
//   const el = document.getElementById('calendar-portal')

//   return (
//     <Portal container={el}>
//       {children}
//     </Portal>
//   )
// }

/**
 * date picker field
 */
export class DatepickerField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { input: { value, onChange, name }, placeholder, className, disabled, readOnly, meta: { touched, error }, dateFormat, showDateIcon } = this.props
    return (
      <>
        <div className="date-icon-container">
          <DatePicker
            selected={value}
            wrapperClassName={className}
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={10}
            scrollableYearDropdown
            todayButton="Today"
            placeholderText={placeholder}
            popperModifiers={{
              preventOverflow: true
            }}
            className="form-control"
            popperPlacement="top-end"
            disabled={disabled}
            dateFormat={dateFormat || 'MM/dd/yyyy'}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            readOnly={readOnly}
            onChange={(date) => this.props.input.onChange(date)}
          />
          {
            showDateIcon ? <i className="fa fa-calendar-o date-icon"></i> : null
          }
        </div>
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}
/**
 * date picker specially for date of birth selection
 * included past dates only, along with year scroll selection
 */
export class DatepickerDOBField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { input: { value, onChange, name }, placeholder, className, disabled, readOnly, meta: { touched, error }, dateFormat, showDateIcon } = this.props
    return (
      <>
        <div className="date-icon-container">
          <DatePicker
            selected={value}
            wrapperClassName={className}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            yearDropdownItemNumber={30}
            todayButton="Today"
            placeholderText={placeholder}
            popperModifiers={{
              preventOverflow: true
            }}
            className="form-control"
            popperPlacement="top-end"
            disabled={disabled}
            dateFormat={dateFormat || 'MM/dd/yyyy'}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            readOnly={readOnly}
            onChange={(date) => this.props.input.onChange(date)}
          />
          {
            showDateIcon ? <i className="fa fa-calendar-o date-icon"></i> : null
          }
        </div>
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}
/**
 * time picker field
 */
export class TimepickerField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { input: { value, onChange, name }, placeholder, className, disabled, readOnly, meta: { touched, error }, timeIntervals, timeCaption } = this.props
    return (
      <>
        <DatePicker
          selected={value}
          wrapperClassName={className}
          placeholderText={placeholder}
          className="form-control"
          disabled={disabled}
          timeCaption={timeCaption || 'time'}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={timeIntervals || 30}
          dateFormat="h:mm aa"
          readOnly={readOnly}
          onChange={(date) => this.props.input.onChange(date)}
        />
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}

/**
 * input range field
 */
export class InputRangeField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      input: { value, onChange, name },
      placeholder,
      disabled,
      readOnly,
      maxValue,
      minValue,
      appendText,
      prependText,
      step,
      meta: { touched, error }
    } = this.props

    return (
      <>
        <InputRange
          disabled={disabled}
          readOnly={readOnly}
          value={value && value.min >= minValue && value.max <= maxValue ? value : { max: maxValue, min: minValue }}
          maxValue={maxValue}
          minValue={minValue}
          placeholder={placeholder}
          step={step || 10}
          formatLabel={(value, type) => {
            return appendText ? `${value} ${appendText}` : prependText ? `${prependText} ${value}` : value
          }}
          onChange={(sel) => this.props.input.onChange(sel)}
        />
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}

/**
 * rating field
 */
export class RatingField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      input: { value, onChange, name },
      placeholder,
      disabled,
      readOnly,
      start,
      stop,
      step,
      fractions,
      showLabel,
      meta: { touched, error }
    } = this.props

    return (
      <>
        <div className="rentmie-rating-input-container">
          <div className="rentmie-rating-container">
            <ReactRating
              start={start || 0}
              stop={stop || 5}
              initialRating={value}
              readonly={readOnly}
              onChange={(val) => onChange(val)}
              emptySymbol="fa fa-star-o rating-empty"
              fullSymbol="fa fa-star-o rating-full"
            />
          </div>
          {
            showLabel ? <div className="rentmie-rating-label">
              {`${value || 0}/${stop || 5}`}
            </div> : null
          }
        </div>
        {touched &&
          (error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          ))}
      </>
    )
  }
}