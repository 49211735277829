import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import classnames from 'classnames'
import Img from './img'
import { URLS } from '../../../config/config';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showImage: false,
    };

    this.onChangeLocation = this.onChangeLocation.bind(this);
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen(this.onChangeLocation)
    this.onChangeLocation(this.props.history.location);
  }
  componentWillUnmount() {
    this.unlisten && this.unlisten();
  }
  onChangeLocation(newLocation) {
    if ([URLS.HOME, URLS.ITEMS, URLS.SERVICE_DETAILS, URLS.ITEM_DETAILS].indexOf(newLocation.pathname) >= 0) {
      this.setState({ showImage: true });
    } else {
      this.setState({ showImage: false });
    }
  }
  render() {
    return (
      <>
        {
          this.state.showImage ? <section className="footer-logo-section">
            <div className="container">
              <div className="footer-logo">
                <Img src="img/footer-logo.png" alt="Rentmie" />
              </div>
            </div>
          </section> : null
        }
        <footer className="footer-panel">
          <div className="container">
            <div className="footer-panel-inner">
              <ul>
                <li>
                  <Link to={URLS.HOME}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to={URLS.ITEMS}>
                    Items
                  </Link>
                </li>
                <li>
                  <Link to={URLS.SERVICES}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to={URLS.ABOUT_US}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to={URLS.FAQ}>
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to={URLS.CONTACT_US}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to={URLS.HELP}>
                    Help
                  </Link>
                </li>
                <li>
                  <Link to={URLS.PRIVACY}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to={URLS.TERMS_CONDITION}>
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
              <p>
                &copy;&nbsp;2020 Rentmie. All Right Reserved
              </p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};
export default connect(mapStateToProps)(withRouter(Footer));