import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import App from './App';
import axios from 'axios';
import { STORAGE, APIS, ACTIONS, PUSHER_CONFIG } from './config/config';
import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';

import * as serviceWorker from './serviceWorker';
import { store } from './store';
import MaterialLoader from './common/loaders/material.loader'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'font-awesome/css/font-awesome.min.css';

const pusherClient = new Pusher(PUSHER_CONFIG.key, {
  cluster: PUSHER_CONFIG.cluster,
});

setPusherClient(pusherClient);

ReactDOM.render(
  <Provider store={store}>
    <App />
    <MaterialLoader />
    <ReduxToastr
      timeOut={3000}
      newestOnTop={true}
      preventDuplicates
      position="top-center"
      transitionIn="bounceInDown"
      transitionOut="bounceOutUp"
      closeOnToastrClick={false}
    />
  </Provider>,
  document.getElementById('root')
);

const token = localStorage.getItem(STORAGE);

if (token) {
  try {
    axios({
      method: 'GET',
      url: APIS.USER.ME,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(success => {
        store.dispatch({
          type: ACTIONS.USER.LOGIN,
          payload: {
            access_token: token,
            user: success.data.data
          },
        });
      })
      .catch(error => {
        localStorage.removeItem(STORAGE);
        store.dispatch({
          type: ACTIONS.USER.LOGOUT,
        });
      });
  } catch (e) {
    localStorage.removeItem(STORAGE);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.REACT_APP_STAGE === 'prod') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
