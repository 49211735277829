import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const CreatePostLoader = Loadable({
    loader: () => import('./createPost'),
    loading: () => <ComponentLoader/>,
});

const CreatePost = () => (
    <CreatePostLoader/>
);

export default CreatePost;