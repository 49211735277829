import { ACTIONS, mergeDataSet, URLS } from '../../config/config';
import { data } from 'jquery';

const initialState = {
  items: null,
  itemsQuery: null,
  services: null,
  servicesQuery: null,
  myItems: null,
  myItemsQuery: null,
  myServices: null,
  myServicesQuery: null,
  singlePostDetails: null,
  bestPosts: null,
  bestPostsQuery: null,
  similarPosts: null,
  similarPostsQuery: null,
  publicItems: null,
  publicItemsQuery: null,
  publicServices: null,
  publicServicesQuery: null,
  favoritePosts: null,
  favoritePostsQuery: null,
  savedPosition: null
};

const findItemAndChangeFavStatusObject = (obj, query, payload) => {
  let findIndex = obj && obj.data && obj.data.length ? obj.data.findIndex(x => x.id == query.post_id) : null

  if (findIndex && findIndex >= 0) {
    return Object.assign({}, {
      ...obj,
      data: data.map((x, i) => {
        if (i == findIndex) {
          return {
            ...x,
            favourite: query.favourite_status == 0 ? null : payload
          }
        } else {
          return x;
        }
      })
    });
  } else {
    return obj
  }
}

const findItemAndChangeFavStatusArray = (array, query, payload) => {
  let findIndex = array && array.length ? array.findIndex(x => x.id == query.post_id) : null

  if (findIndex && findIndex >= 0) {
    return [].concat(array.map((x, i) => {
      if (i == findIndex) {
        return {
          ...x,
          favourite: query.favourite_status == 0 ? null : payload
        }
      } else {
        return x;
      }
    }).map(x => Object.assign({}, x)));
  } else {
    return array
  }
}

const changeItemStatus = (myItems, { id, status, slug }) => {
  if (!myItems || !myItems.data || !myItems.data.length || !id) {
    return myItems
  }
  let oldData = myItems.data
  oldData = oldData.map(x => {
    if (x.id == id) {
      return {
        ...x,
        status: status ? 1 : 0,
        slug: slug ? slug : x.slug
      }
    } else {
      return {
        ...x,
      }
    }
  })
  return {
    ...myItems,
    data: oldData,
  }
}

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POSTS.FETCH_ITEMS:
      return {
        ...state,
        items: mergeDataSet(state.items, action.payload),
        itemsQuery: action.query,
      };
    case ACTIONS.POSTS.FETCH_SERVICES:
      return {
        ...state,
        services: mergeDataSet(state.services, action.payload),
        servicesQuery: action.query,
      };
    case ACTIONS.POSTS.RESET_FETCH_ITEMS:
      return {
        ...state,
        items: null,
        itemsQuery: null,
      };
    case ACTIONS.POSTS.RESET_FETCH_SERVICES:
      return {
        ...state,
        services: null,
        servicesQuery: null,
      };

    case ACTIONS.POSTS.MY_ITEMS:
      return {
        ...state,
        myItems: mergeDataSet(state.myItems, action.payload),
        myItemsQuery: action.query,
      };
    case ACTIONS.POSTS.CHANGE_MY_ITEM_STATUS:
      return {
        ...state,
        myItems: changeItemStatus(state.myItems, action.payload),
        myItemsQuery: action.query,
        myServices: changeItemStatus(state.myServices, action.payload),
        myServicesQuery: action.query,
      };
    case ACTIONS.POSTS.MY_SERVICES:
      return {
        ...state,
        myServices: mergeDataSet(state.myServices, action.payload),
        myServicesQuery: action.query,
      };
    case ACTIONS.POSTS.RESET_MY_ITEMS:
      return {
        ...state,
        myItems: null,
        myItemsQuery: null,
      };
    case ACTIONS.POSTS.RESET_MY_SERVICES:
      return {
        ...state,
        myServices: null,
        myServicesQuery: null,
      };
    case ACTIONS.POSTS.GET_POST_DETAILS:
      return {
        ...state,
        singlePostDetails: action.payload,
      }
    case ACTIONS.POSTS.RESET_GET_POST_DETAILS:
      return {
        ...state,
        singlePostDetails: null,
      }
    case ACTIONS.POSTS.GET_BEST_POSTS:
      return {
        ...state,
        bestPosts: mergeDataSet(state.bestPosts, action.payload),
        bestPostsQuery: action.query,
      };
    case ACTIONS.POSTS.RESET_GET_BEST_POSTS:
      return {
        ...state,
        bestPosts: null,
        bestPostsQuery: null,
      };
    case ACTIONS.POSTS.GET_SIMILAR_POSTS:
      return {
        ...state,
        similarPosts: mergeDataSet(state.similarPosts, action.payload),
        similarPostsQuery: action.query,
      };
    case ACTIONS.POSTS.RESET_GET_SIMILAR_POSTS:
      return {
        ...state,
        similarPosts: null,
        similarPostsQuery: null,
      };
    case ACTIONS.POSTS.FETCH_PUBLIC_ITEMS:
      return {
        ...state,
        publicItems: mergeDataSet(state.publicItems, action.payload),
        publicItemsQuery: action.query,
      };
    case ACTIONS.POSTS.FETCH_PUBLIC_SERVICES:
      return {
        ...state,
        publicServices: mergeDataSet(state.publicServices, action.payload),
        publicServicesQuery: action.query,
      };
    case ACTIONS.POSTS.RESET_PUBLIC_ITEMS:
      return {
        ...state,
        publicItems: null,
        publicItemsQuery: null,
      };
    case ACTIONS.POSTS.RESET_FETCH_PUBLIC_SERVICES:
      return {
        ...state,
        publicServices: null,
        publicServicesQuery: null,
      };
    case ACTIONS.POSTS.SAVE_POSITION:
      return {
        ...state,
        savedPosition: action.payload,
      };
    case ACTIONS.POSTS.RESET_SAVE_POSITION:
      return {
        ...state,
        savedPosition: null,
      };
    case ACTIONS.POSTS.FAVORITE_POSTS:
      return {
        ...state,
        favoritePosts: mergeDataSet(state.favoritePosts, action.payload),
        favoritePostsQuery: action.query,
      };
    case ACTIONS.POSTS.RESET_FAVORITE_POSTS:
      return {
        ...state,
        favoritePosts: null,
        favoritePostsQuery: null,
      };
    case ACTIONS.POSTS.REMOVE_FAVORITE_FROM_LIST:
      let oldFavoritePosts = Object.assign({}, state.favoritePosts)
      if (oldFavoritePosts && oldFavoritePosts.data && oldFavoritePosts.data.length) {
        let findIndex = oldFavoritePosts.data.findIndex(x => x.id == action.payload.post_id)
        if (findIndex >= 0) {
          oldFavoritePosts.data.splice(findIndex, 1);
        }
      }
      return {
        ...state,
        favoritePosts: oldFavoritePosts,
      }
    default:
      return state;
  }
};

export default postsReducer;
