import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { actions as toastrActions } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { ACTIONS, URLS, DEFAULT_USER_IMAGE, SAGA_ACTIONS, extractErrorMessage } from '../../../config/config';
import qs from 'query-string'
import classnames from 'classnames'
import Img from '../../_common/components/img'

import {
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import './userProfileBanner.css'

class UserProfileBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
  }
  componentDidMount() {
    if (window.location.search) {
      let src = window.location.search
      let parsed = qs.parse(src);
      if (parsed && parsed.user) {
        this.props.doFetchUserData({
          slug: parsed.user,
        }, () => { }, (error) => {
          this.showToaster('error', 'Unable to load user profile', extractErrorMessage(error));
        })
      }
    }
  }
  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: this.props.isDesktop ? 'top-left' : 'top-center',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }
  render() {
    const { ownProfile, user, userPublic } = this.props
    const currentData = ownProfile ? user : userPublic;
    let userImage = currentData && currentData.files && currentData.files.FullUrl ? currentData.files.FullUrl : DEFAULT_USER_IMAGE
    return (
      <>
        <div className="banner-text profile-banner-text">
          <div className="container emp-profile">
            <form method="post">
              <div className="row">
                <div className="col-md-4">
                  <div className="profile-img">
                    <Img src={[userImage]} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="profile-head">
                    <h2>{currentData ? currentData.name : 'N/A'}</h2>
                    <a href={`mailto:${currentData ? currentData.email : ''}`}>{currentData ? currentData.email : 'N/A'}</a> <span>|</span>  <a href={`tel:${currentData ? currentData.phone : ''}`}> {currentData ? currentData.phone : 'N/A'}</a>
                    {
                      currentData && currentData.profile && currentData.profile.speciality ? <p className="proile-rating mb-0">
                        Specialty : <span>{currentData.profile.speciality}</span>
                      </p> : null
                    }
                    {
                      currentData && currentData.profile && currentData.profile.business_name ? <>
                        <h5 className="mt-3">{currentData.profile.occupation || 'Employee'}, {currentData.profile.business_name}</h5>
                        <span>{currentData.profile.business_address || 'Address not provided'}</span>
                      </> : null
                    }

                    <div className="d-flex mt-3">
                      <p className="proile-rating pt-0 m-0 mr-4">
                        <i className="fa fa-briefcase" aria-hidden="true"></i> <a href={`tel:${currentData && currentData.profile ? currentData.profile.work_contact_number : ''}`} target="_blank">{currentData && currentData.profile ? currentData.profile.work_contact_number : 'Not Provided'}</a>
                      </p>
                      <p className="proile-rating pt-0 m-0">
                        <i className="fa fa-home" aria-hidden="true"></i> <a href={`tel:${currentData && currentData.profile ? currentData.profile.home_contact_number : ''}`} target="_blank">{currentData && currentData.profile ? currentData.profile.home_contact_number : 'Not Provided'}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  {
                    ownProfile ? <Link className="btn btn-outline-light" to={URLS.EDIT_PROFILE}>Edit Profile</Link> : null
                  }
                  {
                    currentData && !ownProfile ? <OverlayTrigger
                      trigger="click"
                      placement="auto"
                      key="profile-share"
                      rootClose={true}
                      overlay={<Popover id={`popover-positioned-auto`}>
                        <Popover.Title as="h3">Share</Popover.Title>
                        <Popover.Content>
                          <div className="social-share-container">
                            <FacebookShareButton url={'https://rentmie-dev.web.app'} quote={"Rentmie - Place to rent stuffs"} hashtag={"#rentmie"}>
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <EmailShareButton url={'https://rentmie-dev.web.app'} subject={`Rentmie: Profile of ${currentData.name}`} body={`Take a look in the profile of ${currentData.name}`}>
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                            <InstapaperShareButton title={`Take a look in the profile of ${currentData.name}`} url={'https://rentmie-dev.web.app'}>
                              <InstapaperIcon size={32} round />
                            </InstapaperShareButton>
                            <LinkedinShareButton title={`Take a look in the profile of ${currentData.name}`} url={'https://rentmie-dev.web.app'}>
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <PinterestShareButton title={`Take a look in the profile of ${currentData.name}`} media={currentData && currentData.profile && currentData.profile.FullUrl ? currentData.profile.FullUrl : DEFAULT_USER_IMAGE} url={'https://rentmie-dev.web.app'}>
                              <PinterestIcon size={32} round />
                            </PinterestShareButton>
                            <TumblrShareButton title={`Take a look in the profile of ${currentData.name}`} url={'https://rentmie-dev.web.app'}>
                              <TumblrIcon size={32} round />
                            </TumblrShareButton>
                            <TwitterShareButton hashtag={"#rentmie"} title={`Take a look in the profile of ${currentData.name}`} url={'https://rentmie-dev.web.app'}>
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton title={`Take a look in the profile of ${currentData.name}`} url={'https://rentmie-dev.web.app'}>
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </div>
                        </Popover.Content>
                      </Popover>}
                    >
                      <button type="button" className="btn btn-outline-light"><i className="fa fa-share" aria-hidden="true"></i></button>
                    </OverlayTrigger> : null
                  }
                </div>
              </div>

            </form>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
    ...state.responsive,
    userPublic: state.user.userPublic,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showLoader: () => dispatch({
    type: ACTIONS.SHOW_LOADER,
  }),
  hideLoader: () => dispatch({
    type: ACTIONS.HIDE_LOADER,
  }),
  doFetchUserData: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.USER.USER_BY_SLUG,
    payload,
    callbackSuccess,
    callbackError,
  }),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfileBanner));