import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { actions as toastrActions } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';

import HelmetSection from '../_common/components/helmet.section'
import { ACTIONS, checkIfHasMoreItem, extractErrorMessage, SAGA_ACTIONS } from '../../config/config';

import BottomScrollListener from 'react-bottom-scroll-listener';
import BottomLoader from '../_common/components/bottomLoader';

import Img from '../_common/components/img'

import {
  Accordion,
  Card,
} from 'react-bootstrap'

class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      per_page: 1000,
      type: 'faq',
      initialLoaded: false,
      loadingMoreData: false,
    };

    this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
    this.showToaster = this.showToaster.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.fetchFaq = this.fetchFaq.bind(this);
    this.onBottomReached = this.onBottomReached.bind(this);
  }
  componentDidMount() {
    this.loadInitialData()
  }
  componentWillUnmount() {
    this.props.resetFaqs();
  }
  
  loadInitialData() {
    this.props.resetFaqs();
    this.fetchFaq(() => {
      this.setState({ initialLoaded: true });
    });
  }
  fetchFaq(callback) {
    this.props.doFetchFaqs({
      type: this.state.type,
      per_page: this.state.per_page,
      page: this.state.page,
    }, (success) => {
      callback && callback(true);
    }, (error) => {
      this.showToaster('error', 'Error while fetching help', extractErrorMessage(error))
      callback && callback(false);
    });
  }
  onBottomReached() {
    if (this.state.loadingMoreData || !this.props.hasMore || this.state.initialLoading) {
      return
    }
    this.setState(prevState => {
      return {
        loadingMoreData: true,
        page: prevState.page + 1
      }
    }, () => {
      this.fetchFaq((success) => {
        this.setState({ loadingMoreData: false });
      });
    });
  }
  showToaster(type, title, message) {
    this.toastr.add({
      type,
      title,
      position: this.props.isDesktop ? 'top-left' : 'top-center',
      attention: true,
      onAttentionClick: id => { },
      message,
      options: { showCloseButton: true },
      showCloseButton: true,
    });
  }
  render() {
    const {
      faqs,
    } = this.props
    const {
      initialLoaded,
    } = this.state
    return (
      <>
        <HelmetSection title="Rentmie: FAQ" />
        <section className="faq-panel pt-4 pb-3">
          <div className="container">
            <div className="row">
              <div className="col-sm-12" style={{
                minHeight: 'calc(100vh - 330px)'
              }}>
                {
                  faqs && faqs.data && faqs.data.length ? <>
                  <Accordion defaultActiveKey="faq_0">
                  {
                    faqs.data.map((faq, index) => {
                      return <Card key={index}>
                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={`faq_${index}`}>
                        <h2 className="mb-0">
                            <a className="btn btn-link btn-block text-left">
                              <i className="fa fa-question-circle-o" aria-hidden="true"></i> {faq.title}
                            </a>
                          </h2>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`faq_${index}`}>
                          <Card.Body className="text-left" dangerouslySetInnerHTML={{__html: faq.description}}>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    })
                  }
                  </Accordion>
                  </> : <>
                  {
                    initialLoaded ? <div className="text-center mb-3 no-order-available">No faq topics are available!</div> : <div className="text-center mb-3 order-loading">Please wait...</div>
                  }
                  </>
                }
              </div>
            </div>
            <BottomLoader show={this.state.loadingMoreData} />
          </div>
        </section>
        <BottomScrollListener debounce={500} offset={500} onBottom={this.onBottomReached} />
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
    ...state.responsive,
    faqs: state.cms.faqs,
    hasMore: checkIfHasMoreItem(state.cms.faqs)
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showLoader: () => dispatch({
    type: ACTIONS.SHOW_LOADER,
  }),
  hideLoader: () => dispatch({
    type: ACTIONS.HIDE_LOADER,
  }),
  resetFaqs: () => dispatch({
    type: ACTIONS.CMS.RESET_FETCH_FAQ,
  }),
  doFetchFaqs: (payload, callbackSuccess, callbackError) => dispatch({
    type: SAGA_ACTIONS.CMS.FETCH_FAQ,
    payload,
    callbackSuccess,
    callbackError,
  }),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Faq));