import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}
export function* fetchNotifications(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.NOTIFICATION.LIST, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.NOTIFICATION.LIST,
        payload: resp.data.data,
        query: data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* fetchUnreadCount(action) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, APIS.NOTIFICATION.UNREAD_COUNT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.NOTIFICATION.UNREAD_COUNT,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e);
  }
}
export function* markNotificationRead(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.NOTIFICATION.MARK_READ, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.NOTIFICATION.MARK_READ,
        payload: {
          ids: data.notification_ids,
          status: data.read_status
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* deleteNotifications(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.NOTIFICATION.DELETE, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.NOTIFICATION.DELETE,
        payload: {
          ids: data.notification_ids,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}