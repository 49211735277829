import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}
export function* fetchSlot(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.BOOKING.FETCH_SLOTS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* checkAvailability(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.BOOKING.CHECK_ITEM_AVAILABILITY, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* getItemDisabledDates(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.BOOKING.GET_ITEM_DISABLE_DATES, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* bookSlot(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.BOOKING.BOOK_SLOT, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
export function* bookItem(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.BOOKING.BOOK_ITEM, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* priceCalculation(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.BOOKING.PRICE_CALCULATE, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}