import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const TermsLoader = Loadable({
    loader: () => import('./terms'),
    loading: () => <ComponentLoader/>,
});

const Terms = () => (
    <TermsLoader/>
);

export default Terms;