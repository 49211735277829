import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}

export function* fetchCalendarItemDetails(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.GET_ITEM_CALENDAR_DETAILS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.CALENDAR.GET_ITEM_CALENDAR_DETAILS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* updateCalendarItemPrice(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.UPDATE_ITEM_DAY_PRICE, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fetchCalendarServiceDetails(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.GET_SERVICE_CALENDAR_DETAILS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.CALENDAR.GET_SERVICE_CALENDAR_DETAILS,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* updateServiceSlot(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.ADD_EDIT_SERVICE_SLOT, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.CALENDAR.ADD_EDIT_SERVICE_SLOT,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* deleteServiceSlot(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.DELETE_SERVICE_SLOT, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.CALENDAR.DELETE_SERVICE_SLOT,
        payload: resp.data.data,
        query: data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* fetchSlotOrderDetails(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.GET_SLOT_ORDER_DETAILS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* addBulkSlots(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.ADD_BULK_SLOT, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}


export function* createUpdateSlotDay(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.CREATE_SLOT_DAY, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}


export function* deleteSlotDay(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.CALENDAR.DELETE_SLOT_DAY, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}