import React from 'react';
import { connect } from 'react-redux';
import { URLS } from '../../config/config';
import qs from 'query-string'

function requireNoAuth(ComposedComponent, storageFunc) {
  class LoginAuthentication extends React.Component {
    componentWillMount() {
      this.checkRouteFunc(this.props);
    }

    componentWillReceiveProps(next_props) {
      this.checkRouteFunc(next_props);
    }

    componentWillUpdate(nextProps) {
      this.checkRouteFunc(nextProps);      
    }

    checkRouteFunc(nextProps) {
      storageFunc.pushRoute(`${this.props.history.location.pathname}${this.props.history.location.search}`);
      if (nextProps.authenticated) {
        let searchStr = window.location.search
        let parsed = searchStr ? qs.parse(searchStr) : null;
        if (nextProps.user && parsed && parsed.redirect) {
          this.props.history.push(decodeURIComponent(parsed.redirect));
        } else if (nextProps.user && nextProps.user.first_time_login == 1) {
          this.props.history.push(URLS.USER_INTEREST)
        } else {
          this.gotoPrivateRoute(nextProps);
        }
        // if (nextProps.user && nextProps.user.first_time_login == 1) {
        //   this.props.history.push(URLS.USER_INTEREST)
        // } else if (window.location.search) {
        //   let parsed = qs.parse(window.location.search);
        //   if (parsed.redirect) {
        //     this.props.history.push(decodeURIComponent(parsed.redirect));
        //   } else {
        //     this.gotoPrivateRoute();
        //   }
        // } else {
        //   this.gotoPrivateRoute();
        // }
      }
    }

    gotoPrivateRoute(nextProps = null) {
      let route = storageFunc.getFirstPrivateRoute();
      if (route == URLS.USER_INTEREST && nextProps.user && nextProps.user.first_time_login == 0) {
        this.props.history.push(URLS.HOME)
      } else {
        this.props.history.push(route);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.user.isAuthenticated,
      user: state.user.user,
    };
  }

  return connect(mapStateToProps)(LoginAuthentication);
}

export default requireNoAuth;
