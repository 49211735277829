import { ACTIONS } from '../../config/config';

const initialState = {
  messages: [],
  from: 0,
  limit: 10,
  total: 0,
  isMoreAvailable: false,
};

const mergeMessageList = (state, payload, query) => {
  if (!state || !payload || !query) {
    return {}
  }
  if (query.from == 0) {
    // reset list
    return {
      messages: payload.messages.reverse(),
      from: payload.messages.length,
      total: payload.count,
      isMoreAvailable: payload.count > payload.messages.length
    }
  } else {
    // merge list
    let oldList = state.messages
    let newList = payload.messages.reverse();
    let length = newList.length
    return {
      messages: newList.concat(oldList),
      from: state.from + length,
      total: payload.count,
      isMoreAvailable: payload.count > (state.from + length)
    }
  }
}

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.MESSAGE.SEND_MESSAGE:
      let newArr = state.messages.concat([action.payload])
      return {
        ...state,
        messages: newArr,
        from: state.from + 1,
        total: state.total + 1,
        isMoreAvailable: (state.total + 1) > newArr.length,
      };
    case ACTIONS.MESSAGE.MY_MESSAGES:
      return {
        ...state,
        ...mergeMessageList(state, action.payload, action.query)
      };
    case ACTIONS.MESSAGE.RESET_MESSAGES:
      return {
        ...state,
        messages: [],
        from: 0,
        limit: 10,
        total: 0,
        isMoreAvailable: false,
      };
    default:
      return state;
  }
};

export default messageReducer;
