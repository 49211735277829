import { ACTIONS, mergeDataSet } from '../../config/config';

const initialState = {
  myOrders: null,
  myOrdersQuery: null,
  myBookings: null,
  myBookingsQuery: null,
  singleOrderDetails: null,
  savedPosition: null,
}

const changeBookStatus = (state, payload) => {
  if (!payload || !payload.id || !payload.type || !payload.status) {
    return {}
  }
  if (payload.type == 'order') {
    let oldOrders = state.myOrders.data
    oldOrders = oldOrders.map(x => {
      if (x.id == payload.id) {
        return {
          ...x,
          book_status: payload.status
        }
      } else {
        return {
          ...x
        }
      }
    })
    return {
      myOrders: {
        ...state.myOrders,
        data: oldOrders
      }
    }
  } else {
    let oldBookings = state.myBookings.data
    oldBookings = oldBookings.map(x => {
      if (x.id == payload.id) {
        return {
          ...x,
          book_status: payload.status
        }
      } else {
        return {
          ...x
        }
      }
    })
    return {
      myBookings: {
        ...state.myBookings,
        data: oldBookings
      }
    }
  }
}

const updateAfterPostReview = (orders, payload, query) => {
  if (!orders || !orders.data || !orders.data.length || !payload || !query) {
    return orders
  }
  let oldData = orders.data.map(x => {
    if (x.id == query.order_id) {
      return {
        ...x,
        posted_review: payload
      }
    } else {
      return x
    }
  })
  return Object.assign({}, {
    ...orders,
    data: oldData,
  })
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ORDERS.MY_ORDERS:
      return {
        ...state,
        myOrders: mergeDataSet(state.myOrders, action.payload),
        myOrdersQuery: action.query,
      };
    case ACTIONS.ORDERS.MY_ORDERS_POST_REVIEW:
      return {
        ...state,
        myOrders: updateAfterPostReview(state.myOrders, action.payload, action.query),
      }
    case ACTIONS.ORDERS.RESET_MY_ORDERS:
      return {
        ...state,
        myOrders: null,
        myOrdersQuery: null,
      };
    case ACTIONS.ORDERS.MY_BOOKINGS:
      return {
        ...state,
        myBookings: mergeDataSet(state.myBookings, action.payload),
        myBookingsQuery: action.query,
      };
    case ACTIONS.ORDERS.RESET_MY_BOOKINGS:
      return {
        ...state,
        myBookings: null,
        myBookingsQuery: null,
      };
    case ACTIONS.ORDERS.SINGLE_ORDER_DETAILS:
      return {
        ...state,
        singleOrderDetails: action.payload,
      };
    case ACTIONS.ORDERS.RESET_SINGLE_ORDER_DETAILS:
      return {
        ...state,
        singleOrderDetails: null,
      };
    case ACTIONS.ORDERS.SAVE_POSITION:
      return {
        ...state,
        savedPosition: {
          ...action.payload,
          currentBookingPage: state.myBookings && state.myBookings.current_page ? state.myBookings.current_page : null,
          currentOrderPage: state.myOrders && state.myOrders.current_page ? state.myOrders.current_page : null,
        }
      }
    case ACTIONS.ORDERS.CANCEL_ORDER:
      return {
        ...state,
        ...changeBookStatus(state, action.payload)
      }
    case ACTIONS.ORDERS.RESET_POSITION:
      return {
        ...state,
        savedPosition: null
      }
    case ACTIONS.ORDERS.RESET_ALL:
      return {
        ...state,
        myOrders: null,
        myOrdersQuery: null,
        myBookings: null,
        myBookingsQuery: null,
        singleOrderDetails: null,
      };
    default:
      return state;
  }
};

export default orderReducer;
