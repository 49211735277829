import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";

import { bindActionCreators } from 'redux';
import { actions as toastrActions } from 'react-redux-toastr';

import { SITE_NAME, SITE_URL, SITE_LANGUAGE, SITE_LOGO } from '../../../config/config'

class HelmetSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.toastr = bindActionCreators(toastrActions, this.props.dispatch);
        this.showToaster = this.showToaster.bind(this);
    }
    showToaster(type, title, message) {
        this.toastr.add({
          type,
          title,
          position: 'top-left',
          attention: true,
          onAttentionClick: id => { },
          message,
          options: { showCloseButton: true },
          showCloseButton: true,
        });
    }
    render() {
        const { 
            title,
            description,
            keywords,
            subject,
            language,
            summary,
            url,
            twitter_card,
            twitter_site,
            twitter_title,
            twitter_description,
            twitter_creator,
            twitter_image,
            og_title,
            og_type,
            og_url,
            og_image,
            og_description,
            og_site_name,
        } = this.props

        let currentUrl = window.location.href

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{title || SITE_NAME}</title>
                    <meta name="description" content={description || SITE_NAME}/>
                    <meta name="keywords" content={keywords || SITE_NAME}/>
                    <meta name="subject" content={subject || SITE_NAME}/>
                    <meta name="summary" content={summary || SITE_NAME}/>
                    <meta name="language" content={language || SITE_LANGUAGE}/>
                    <meta name="url" content={url || currentUrl || SITE_URL}/>
                    <meta name="twitter:card" content={twitter_card || SITE_NAME}/>
                    <meta name="twitter:site" content={twitter_site || SITE_URL}/>
                    <meta name="twitter:title" content={twitter_title || SITE_NAME}/>
                    <meta name="twitter:description" content={twitter_description || SITE_NAME}/>
                    <meta name="twitter:creator" content={twitter_creator || SITE_NAME}/>
                    <meta name="twitter:image" content={twitter_image || SITE_LOGO}/>
                    <meta property="og:title" content={og_title || SITE_NAME}/>
                    <meta property="og:type" content={og_type || SITE_NAME}/>
                    <meta property="og:url" content={og_url || currentUrl || SITE_URL}/>
                    <meta property="og:image" content={og_image || SITE_LOGO}/>
                    <meta property="og:description" content={og_description || SITE_NAME}/>
                    <meta property="og:site_name" content={og_site_name || SITE_NAME}/>
                </Helmet>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(withRouter(HelmetSection));