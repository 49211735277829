import { ACTIONS } from '../../config/config';

const initialState = {
  currentBookingData: null,
  currentPost: null,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BOOKING.SET_BOOKING_DATA:
      return {
        ...state,
        currentBookingData: action.payload,
      };
    case ACTIONS.BOOKING.RESET_BOOKING_DATA:
      return {
        ...state,
        currentBookingData: null,
      };
    case ACTIONS.BOOKING.SET_BOOKING_POST:
      return {
        ...state,
        currentPost: action.payload,
      };
    case ACTIONS.BOOKING.RESET_BOOKING_POST:
      return {
        ...state,
        currentPost: null,
      };
    case ACTIONS.BOOKING.RESET_ALL:
      return {
        ...state,
        currentBookingData: null,
        currentPost: null,
      };
    default:
      return state;
  }
};

export default bookingReducer;
