import createSagaMiddleware from "redux-saga";
import timerMiddleware from 'redux-timer-middleware';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers/rootReducer';
import { sagas } from '../sagas/index';


let middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(timerMiddleware);

let middleware = applyMiddleware(...middlewares);

// add the redux dev tools process.env.NODE_ENV !== 'production' &&
if ((['prod', 'stage'].indexOf(process.env.REACT_APP_STAGE) < 0) && window.devToolsExtension) {
  middleware = compose(middleware, window.devToolsExtension());
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'toastr', 'posts', 'user', 'notification']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store)

sagaMiddleware.run(sagas);

export { store, persistor };
