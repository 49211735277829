import React from 'react';

import './compLoader.css'

class ComponentLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  render() {
    return (
      <>
        <div className="comp-loader">
          <div className="text-center">
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
          <div className="text-center">
            Loading...
          </div>
        </div>
      </>
    )
  }
}

export default ComponentLoader;