import moment from 'moment'

const nameLabelMap = {
  'name': 'Name',
  'email': 'Email',
  'password': 'Password',
  'recaptcha': 'Re-Captcha',
  'phone': 'phone',
  'business_name': 'Business name',
  'business_address': 'Business address',
  'work_contact_number': 'Work contact number',
  'home_contact_number': 'Home contact number',
  'specialty': 'Specialty',
  'category': 'Category',
  'sub_category': 'Sub-category',
  'title': 'Title',
  'description': 'Description',
  'category_id': 'Category',
  'sub_category_id': 'Sub-category',
  'condition': 'Condition',
  'image_id': 'Image',
  'video_id': 'Video',
  'cost': 'Cost',
  'cost_type': 'Cost definition',
  'available_from': 'Available from date',
  'delivery_type': 'Delivery type',
  'location': 'Location',
  'available_at': 'Available at',
  'days_of_operation': 'Days of operation',
  'service_start_date': 'Service start date',
  'service_end_date': 'Service end date',
  'service_start_time': 'Service start time',
  'service_end_time': 'Service end time',
  'slot_duration': 'Slot duration',
  'xxxxxxxx': 'xxxxxxxx',
}

const FieldRegExp = [
  {
    regex: new RegExp('service_days\\[[(0-9)+]\\].day'),
    value: 'Service Day'
  },
  {
    regex: new RegExp('service_days\\[[(0-9)+]\\].start_time'),
    value: 'Service Start time'
  },
  {
    regex: new RegExp('service_days\\[[(0-9)+]\\].end_time'),
    value: 'Service End time'
  },
  {
    regex: new RegExp('service_days\\[[(0-9)+]\\].sub_services\\[[(0-9)+]\\].name'),
    value: 'Service Name'
  },
  {
    regex: new RegExp('service_days\\[[(0-9)+]\\].sub_services\\[[(0-9)+]\\].duration'),
    value: 'Service duration'
  },
  {
    regex: new RegExp('service_days\\[[(0-9)+]\\].sub_services\\[[(0-9)+]\\].cost'),
    value: 'Service cost'
  },
]

const getSerializedName = (name) => {
  if (!name) {
    return 'Field'
  }
  if (nameLabelMap[name]) {
    return nameLabelMap[name]
  }
  let res = name
  for (let i = 0; i < FieldRegExp.length; i++) {
    if (FieldRegExp[i].regex.test(name)) {
      res = FieldRegExp[i].value
      break;
    }
  }
  return res
}

export const emailValidator = value =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

export const password = value => !/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(value) ? 'Password must contain at least one uppercase, one lowercase, one numeric and one special character.' : undefined
export const passwordMin6 = value => !/[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(value) ? 'Password must contain at least 8 character.' : undefined
export const textOnly = value =>
  value && !/^[A-Z ]*$/i.test(value)
    ? 'Title should contain Alphabets and blank spaces only'
    : undefined;

export const numberOnly = value =>
    value && !/^[0-9]*$/i.test(value)
      ? 'Should contain numbers only'
      : undefined;

export const permittedLength = value =>
  value && !(value.length > 1 && value.length <= 255)
    ? 'Title should contain 255 characters max'
    : undefined;

export const required = (value, allValue, props, name) => (value && value.trim() ? undefined : `${getSerializedName(name) || name} is required`);
export const requiredFileMulti = (value, allValue, props, name) => (value && value.length ? undefined : `${getSerializedName(name) || name} is required`);
export const requiredFileMultiOne = (value, allValue, props, name) => (value && value.length == 1 ? undefined : `Max 1 ${getSerializedName(name) || name} can be selected`);
export const requiredMin4 = (value, allValue, props, name) => (value && value.length && value.length >= 4 ? undefined : `${getSerializedName(name) || name} at least 4 item are required`);
export const requiredMax10 = (value, allValue, props, name) => (value && value.length && value.length <= 10 ? undefined : `Max 10 ${getSerializedName(name) || name} can be uploaded`);

export const requiredNumber = (value, allValue, props, name) => (value !== undefined && value != 0 ? undefined : `${getSerializedName(name) || name} is required`);

export const requiredInteger = (value, allValue, props, name) => (value !== undefined && value != 0 && ((value - Math.floor(value)) == 0) ? undefined : `${getSerializedName(name) || name} must be a positive real number, decimal not allowed`);

export const requiredNumberMin30 = (value, allValue, props, name) => ((value !== undefined) && (value != 0) && (value >= 30) && (value <= (8*60)) ? undefined : `${getSerializedName(name) || name} can be between 30 & ${8*60}`);

export const required_select = (value, allValue, props, name) => (value && value.length ? undefined : `${getSerializedName(name) || name} is required`);

export const required_location = value => (value && value.lat && value.lng ? undefined : 'You must select a valid location in map');

export const requiredDate = value => (value !== undefined && moment.isMoment(value) && moment(value).isValid() ? undefined : value != undefined && moment(value).isValid() ? undefined : 'Valid date required');

export const requiredTime = value => (value !== undefined && moment.isMoment(value) && moment(value).isValid() ? undefined : value != undefined && moment(value).isValid() ? undefined : 'Valid time required');

export const requiredDateRange = value => (value !== undefined && value.start && value.end && moment(value.start).isValid() && moment(value.end).isValid() ? undefined : 'Valid date range required');

export const required_custom_select = (value, allValue, props, name) => (value && value.value ? undefined :  `${getSerializedName(name) || name} is required`) 

export const passwordMinLength8 = value =>
  value && value.length < 8 ? 'Password should be minimum 8 chars' : undefined;

export const phoneValidator = value =>
  !/^[0-9]{10}$/g.test(value) ? 'Invalid phone number' : undefined;

  export const usZipCodeValidator = value =>
  value?
  ((!/^[0-9]{5}(?:-[0-9]{4})?$/g.test(value)) ? 'Invalid Zip code' : undefined):undefined
