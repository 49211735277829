import { ACTIONS, CATEGORY_STATIC } from '../../config/config';

const initialState = {
  allCategories: [],
  serviceCategories: [],
  itemCategories: [],
  serviceFeaturedCategories: [],
  itemFeaturedCategories: [],
  conditions: [],
};

const getServiceCategories = (data) => {
  if (!data || !data.length) {
    return []
  }
  return data.filter(x => x.category_type == CATEGORY_STATIC.SERVICE);
}

const getItemCategories = (data) => {
  if (!data || !data.length) {
    return []
  }
  return data.filter(x => x.category_type == CATEGORY_STATIC.ITEM);
}

const getFeaturedServiceCategories = (data) => {
  if (!data || !data.length) {
    return []
  }
  return data.filter(x => x.category_type == CATEGORY_STATIC.SERVICE && x.feature == CATEGORY_STATIC.IS_FEATURED);
}

const getFeaturedItemCategories = (data) => {
  if (!data || !data.length) {
    return []
  }
  return data.filter(x => x.category_type == CATEGORY_STATIC.ITEM && x.feature == CATEGORY_STATIC.IS_FEATURED);
}

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CATEGORIES.ALL_CATEGORIES_TREE:
      return {
        ...state,
        allCategories: action.payload,
        serviceCategories: getServiceCategories(action.payload),
        itemCategories: getItemCategories(action.payload),
        serviceFeaturedCategories: getFeaturedServiceCategories(action.payload),
        itemFeaturedCategories: getFeaturedItemCategories(action.payload),
      };
    case ACTIONS.CATEGORIES.GET_ITEM_CONDITIONS:
      return {
        ...state,
        conditions: action.payload
      }
    default:
      return state;
  }
};

export default categoryReducer;
