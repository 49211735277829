import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE, URLS } from '../config/config';

function* setLoader(status) {
  yield put({
      type: status ? ACTIONS.SHOW_LOADER : ACTIONS.HIDE_LOADER,
  });
}


export function* loginUser(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.LOGIN, data);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
        localStorage.setItem(STORAGE, resp.data.data.access_token);
        yield put({
          type: ACTIONS.USER.LOGIN,
          payload: resp.data.data,
        });
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* socialLoginUser(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.SOCIAL_LOGIN, data);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
        localStorage.setItem(STORAGE, resp.data.data.access_token);
        yield put({
          type: ACTIONS.USER.LOGIN,
          payload: resp.data.data,
        });
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* verifyAccount(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.VERIFY_ACCOUNT, data);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* getMe(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.USER.ME, data, true);
      yield setLoader(false);
      if (resp && resp.data) {
        yield put({
          type: ACTIONS.USER.ME,
          payload: {
            ...resp.data.data,
          },
        });
      }
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}


export function* logout(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.GET, APIS.USER.LOGOUT, data, true);
      yield setLoader(false);
      localStorage.removeItem(STORAGE);
      yield put({
        type: ACTIONS.USER.LOGOUT,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* userInterest(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.USER_INTEREST, data, true);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* updateProfile(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.UPDATE_PROFILE, data, true);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* registerUser(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.REGISTER, data);
      yield setLoader(false);
      if (resp && resp.data && resp.data.data) {
        localStorage.setItem(STORAGE, resp.data.data.access_token);
        yield put({
          type: ACTIONS.USER.LOGIN,
          payload: resp.data.data,
        });
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* forgotPassword(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.FORGOT_PASSWORD, data);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* resetPassword(action) {
    try {
      const data = action.payload;
      yield setLoader(true);
      const resp = yield call(CallApi.POST, APIS.USER.RESET_PASSWORD, data);
      yield setLoader(false);
      if (resp.status >= 200 && resp.status < 300 && resp.data) {
        action && action.callbackSuccess && action.callbackSuccess(resp.data);
      } else {
        action && action.callbackError && action.callbackError(resp.data);
      }
    } catch (e) {
      yield setLoader(false);
      action && action.callbackError && action.callbackError(e);
    }
}

export function* resendVerificationEmail(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.RESEND_VERIFICATION_EMAIL, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* getUserBySlug(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.USER_BY_SLUG, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.USER.USER_BY_SLUG,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}


export function* getSavedCards(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.SAVED_CARDS, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.USER.SAVED_CARDS,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* deleteCard(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.DELETE_CARD, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* saveBackAccount(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.SAVE_BANK_ACCOUNT, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* saveIdentityInformation(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.SAVE_IDENTITY_VERIFICATION, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* changePassword(action) {
  try {
    const data = action.payload;
    yield setLoader(true);
    const resp = yield call(CallApi.POST, APIS.USER.CHANGE_PASSWORD, data, true);
    yield setLoader(false);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    yield setLoader(false);
    action && action.callbackError && action.callbackError(e);
  }
}
