import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const ItemDetailsLoader = Loadable({
    loader: () => import('./itemDetails'),
    loading: () => <ComponentLoader/>,
});

const ItemDetails = () => (
    <ItemDetailsLoader/>
);

export default ItemDetails;