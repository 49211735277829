import { ACTIONS } from '../../config/config';

const initialState = {
  notifications: [],
  from: 0,
  limit: 10,
  total: 0,
  isMoreAvailable: false,
  unreadCount: null,
};

const mergeMessageList = (state, payload, query) => {
  if (!state || !payload || !query) {
    return {}
  }
  if (query.from == 0) {
    // reset list
    return {
      notifications: payload.messages,
      from: payload.messages.length,
      total: payload.count,
      isMoreAvailable: payload.count > payload.messages.length
    }
  } else {
    // merge list
    let oldList = state.notifications
    let newList = payload.messages;
    let length = newList.length
    return {
      notifications: oldList.concat(newList),
      from: state.from + length,
      total: payload.count,
      isMoreAvailable: payload.count > (state.from + length)
    }
  }
}

const markRead = (state, {ids, status}) => {
  if (!ids || !ids.length) {
    return {
      notifications: state.notifications.map(x => ({
        ...x,
        read: 1,
      })),
      unreadCount: 0,
    }
  }
  let count = state.unreadCount
  let newNotifications = state.notifications.map((x, index) => {
    if (ids.indexOf(x.id) >= 0) {
      status ? count-- : count++;
      return {
        ...x,
        read: status
      }
    } else {
      return {
        ...x
      }
    }
  })
  return {
    notifications: newNotifications,
    unreadCount: count,
  }
}
const deleteNotifications = (state, {ids}) => {
  if (!ids || !ids.length) {
    return {
      notifications: [],
      unreadCount: 0,
    }
  }
  let count = state.unreadCount
  let newNotifications = state.notifications.filter((x, index) => {
    let fi = ids.indexOf(x.id)
    if (fi >= 0) {
      count--;
    }
    return fi < 0
  })
  return {
    notifications: newNotifications,
    unreadCount: count,
  }
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.NOTIFICATION.LIST:
      return {
        ...state,
        ...mergeMessageList(state, action.payload, action.query),
      };
    case ACTIONS.NOTIFICATION.ADD_NEW_NOTIFICATION:
      let oldNotification = state.notifications
      oldNotification.unshift(action.payload)
      let total = state.total ? state.total + 1 : 1
      return {
        ...state,
        unreadCount: state.unreadCount ? state.unreadCount + 1 : 1,
        notifications: oldNotification,
        from: oldNotification.length,
        limit: state.limit || 10,
        total,
        isMoreAvailable: oldNotification.length < total,
      }
    case ACTIONS.NOTIFICATION.UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case ACTIONS.NOTIFICATION.INC_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: state.unreadCount ? state.unreadCount + 1 : 1,
      };
    case ACTIONS.NOTIFICATION.MARK_READ:
      return {
        ...state,
        ...markRead(state, action.payload)
      };
    case ACTIONS.NOTIFICATION.DELETE:
      return {
        ...state,
        ...deleteNotifications(state, action.payload)
      };
    case ACTIONS.NOTIFICATION.RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
        from: 0,
        limit: 10,
        total: 0,
        isMoreAvailable: false,
      };
    case ACTIONS.NOTIFICATION.RESET_ALL:
      return {
        ...state,
        notifications: [],
        from: 0,
        limit: 10,
        total: 0,
        isMoreAvailable: false,
        unreadCount: 0,
      };
    default:
      return state;
  }
};

export default notificationReducer;
