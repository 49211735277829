import { ACTIONS } from "../../config/config";

const initialState = {
    isDesktop: false,
    isTab: false,
    isMobile: false,
};

const responsiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.RESPONSIVE.SET_DESKTOP:
            return {
                ...state,
                isDesktop: true,
                isTab: false,
                isMobile: false,
            }
        case ACTIONS.RESPONSIVE.SET_MOBILE:
            return {
                ...state,
                isDesktop: false,
                isTab: false,
                isMobile: true,
            }
        case ACTIONS.RESPONSIVE.SET_TAB:
            return {
                ...state,
                isDesktop: false,
                isTab: true,
                isMobile: false,
            }
        default:
            return state;
    }
}
export default responsiveReducer;
