import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const EditPostLoader = Loadable({
    loader: () => import('./editPost'),
    loading: () => <ComponentLoader/>,
});

const EditPost = () => (
    <EditPostLoader/>
);

export default EditPost;