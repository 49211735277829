import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const MyPostsLoader = Loadable({
    loader: () => import('./myposts'),
    loading: () => <ComponentLoader/>,
});

const MyPosts = () => (
    <MyPostsLoader/>
);

export default MyPosts;