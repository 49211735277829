import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const ServicesLoader = Loadable({
    loader: () => import('./services'),
    loading: () => <ComponentLoader/>,
});

const Services = () => (
    <ServicesLoader/>
);

export default Services;