import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import classnames from 'classnames'
import Img from './img'

import './bottomLoader.css'

class BottomLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  render() {
    const {
      show
    } = this.props
    if (!show) {
      return null
    }
    return (
      <>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};
export default connect(mapStateToProps)(withRouter(BottomLoader));