import React from 'react';
import Loadable from 'react-loadable';
import ComponentLoader from '../_common/components/compLoader'

const ForgetPasswordLoader = Loadable({
    loader: () => import('./forgetPassword'),
    loading: () => <ComponentLoader/>,
});

const ForgetPassword = () => (
    <ForgetPasswordLoader/>
);

export default ForgetPassword;