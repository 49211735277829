import React from 'react';
import {
  connect
} from 'react-redux';
import { URLS } from '../../config/config';

function requireAuth(ComposedComponent, storageFunc) {
  class RequireAuthentication extends React.Component {
    componentWillMount() {
      this.checkRouteFunc();
    }

    componentWillReceiveProps(next_props) {
      this.checkRouteFunc();
    }

    componentWillUpdate(nextProps) {
      this.checkRouteFunc();
    }

    checkRouteFunc() {
      let routeToPush = `${this.props.history.location.pathname}${this.props.history.location.search}`
      storageFunc.pushRoute(routeToPush);
      if (!this.props.authenticated) {
        this.gotoPublicRoute(routeToPush);
      }
    }

    gotoPublicRoute(routeToPush) {
      let route = storageFunc.getFirstPublicRoute();
      this.props.history.push(`${route}?redirect=${encodeURIComponent(routeToPush)}`);
    }

    render() {
      // return this.props.authenticated ? <ComposedComponent {...this.props} /> : null;
      return <ComposedComponent {
        ...this.props
      }
      />;
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.user.isAuthenticated,
    };
  }

  return connect(mapStateToProps)(RequireAuthentication);
}

export default requireAuth;
