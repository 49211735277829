import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string'

function openRoutes(ComposedComponent, storageFunc) {
  class OpenRoutes extends React.Component {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.user,
      authenticated: state.user.isAuthenticated,
    };
  }

  return connect(mapStateToProps)(OpenRoutes);
}

export default openRoutes;
