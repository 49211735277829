import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  Navbar,
  Nav,
  NavDropdown,
} from 'react-bootstrap';
import classnames from 'classnames'
import Img from './img'

class HomeBannerText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  render() {
    const {
      title,
      subTitle,
      linkBtn,
      linkBtnText,
      linkTo,
    } = this.props
    return (
      <>
        <div className="banner-text">
          <div className="container">
            <h1>{title}</h1>
            <p>{subTitle}</p>
            {
              linkBtn ? <div className="text-center pb-3">
                <Link to={linkTo} className="more-btn-solid">{linkBtnText}</Link>
              </div> : null
            }
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};
export default connect(mapStateToProps)(withRouter(HomeBannerText));