import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  Tabs,
  Tab,
} from 'react-bootstrap';
import classnames from 'classnames'
import Img from './img'
import HomeBannerSearchForm from './homeBannerSearchForm'
import { URLS } from '../../../config/config';

class HomeBannerSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  render() {
    return (
      <>
        <div className="search-section-wrapper">
          <div className="container">
            <div className="search-section-inner-wrapper">
              <Tabs defaultActiveKey="items" variant="pills" id="search-home">
                <Tab eventKey="items" title="Items for Rent">
                  <div className="search-section-panel">
                    <div className="search-section-panel-inner home-section">
                      <HomeBannerSearchForm url={URLS.ITEMS} />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="services" title="Services Offered">
                  <div className="search-section-panel">
                    <div className="search-section-panel-inner home-section">
                      <HomeBannerSearchForm url={URLS.SERVICES} />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};
export default connect(mapStateToProps)(withRouter(HomeBannerSearch));